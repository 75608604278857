import { FC, ElementType } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from '@mantine/core';

import { controlMenuPages } from '@/mock-data/navbar-menu';

import { useStyles } from './styles';

interface IMenuProps {
  setIsOpen: (isOpen: boolean) => void;
}

export const NavMenu: FC<IMenuProps> = ({ setIsOpen }) => {
  const { classes } = useStyles();
  const location = useLocation();
  const currentPage = location.pathname.split('/statistics/')[1] || '';

  const handleClickClose = () => {
    return setIsOpen(false);
  };

  return (
    <>
      {controlMenuPages.map((item, index) => {
        const isActive = item.value === currentPage;
        const Icon = item.icon as ElementType;
        return (
          <NavLink
            className={classes.navLinkItem}
            key={index}
            label={item.label}
            icon={<Icon stroke={isActive ? '#101828' : '#667085'} />}
            component={Link}
            onClick={() => handleClickClose()}
            to={item.value}
            active={item.value === currentPage}
            data-cy={item.label}
          />
        );
      })}
    </>
  );
};
