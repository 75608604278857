import { FC, useEffect, useState, useCallback } from 'react';
import { useStyles } from './styles';
import { UseFormReturnType } from '@mantine/form';
import { DatePickerInput as DefaultDatePicker } from '@mantine/dates';
import 'dayjs/locale/ru';

interface IDatePickerProps {
  fieldName: string;
  form: UseFormReturnType<any>;
  className?: string;
  label?: string;
  disabled?: boolean;
  clearable?: boolean;
  placeholder?: string;
}

export const DatePicker: FC<IDatePickerProps> = ({
  form,
  fieldName,
  className,
  disabled,
  label,
  placeholder,
  clearable = false
}) => {
  const { classes, cx } = useStyles();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(Math.random());
  }, [form]);

  const handleDatePickerChange = useCallback(
    (value: Date) => {
      if (value) {
        const utcDate = new Date(Date.UTC(value?.getFullYear(), value.getMonth(), value.getDate()));
        const isoDate = utcDate.toISOString();
        form?.setFieldValue(fieldName, isoDate);
      } else {
        form?.setFieldValue(fieldName, null);
      }
    },
    [fieldName, form]
  );

  return (
    <DefaultDatePicker
      data-cy="datePicker"
      key={key}
      className={cx(classes.date, className)}
      locale="ru"
      valueFormat="DD.MM.YYYY"
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      clearable={clearable}
      defaultValue={
        form.getInputProps(fieldName)?.value?.length &&
        form.getInputProps(fieldName)?.value?.length !== null
          ? new Date(form.getInputProps(fieldName).value)
          : undefined
      }
      onChange={handleDatePickerChange}
      error={form.getInputProps(fieldName).error}
      maxDate={new Date()}
    />
  );
};
