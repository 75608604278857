import { z } from 'zod';
import i18n from '@/entities/i18next';
export const validateSchemeBrandingCommon = z.object({
  CompanyName: z
    .string()
    .min(1, { message: i18n.t('validation.typeCompanyName') })
    .max(64, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 }) }),
  MainLogoUrl: z.string().min(1, { message: i18n.t('validation.chooseLogo') }),
  BrandColor: z
    .string()
    .length(7, { message: i18n.t('validation.brandColorHEXFormat') })
    .regex(/^#/, { message: i18n.t('validation.brandColorHEXFormat') })
});
