import { Button, Text } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons';
import { useStyles } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { themeColors } from '@/theme/colors';
import { Modal } from '@components/modal';
import { CreateLinkModalContent } from '@/containers/pages/filials/edit/components/tabs/invite-links/components/createLinkModalContent';

interface ICreateInviteLinkProps {
  handleChange?: () => void;
  CustomerId: number;
}
export const CreateInviteLink = ({ handleChange, CustomerId }: ICreateInviteLinkProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isTablet = useMediaQuery('(max-width: 1200px)');
  return (
    <>
      <Button
        className={classes.addButton}
        size="sm"
        variant="outline"
        rightIcon={<IconPlus color={themeColors.brandGray[0]} />}
        onClick={() => open()}
      >
        <Text>{t('filials.inviteLinks.createLink')}</Text>
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        title={t(`filials.inviteLinks.creatingLink`)}
        size={isTablet ? '70%' : '40%'}
      >
        <CreateLinkModalContent
          opened={opened}
          handleClose={close}
          handleChange={handleChange}
          isEditing={false}
          CustomerId={CustomerId}
        />
      </Modal>
    </>
  );
};
