import { useTranslation } from 'react-i18next';
import { Box, Button, Group, LoadingOverlay, Text, UnstyledButton } from '@mantine/core';
import { Dispatch, FC, SetStateAction } from 'react';
import { useStyles } from '../styles';
import { IDomain, IRecordDetails } from '@/entities/admin-app/email';
import { useLazyDeleteDomainQuery } from '@/entities/admin-app/email/api';
import { showNotification } from '@mantine/notifications';
import { Controls } from '@/components/modal/components/controls';

interface IDomainItem {
  handleChange?: () => void;
  domain: IDomain | IRecordDetails | undefined;
  setDeleteStep: Dispatch<SetStateAction<boolean>>;
  setDomainDetails: Dispatch<SetStateAction<IDomain | IRecordDetails | undefined>>;
}

const DeleteDomain: FC<IDomainItem> = ({
  domain,
  handleChange,
  setDeleteStep,
  setDomainDetails
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [deleteDomain, { isLoading }] = useLazyDeleteDomainQuery();

  const handleDelete = async () => {
    if (domain && domain.Id) {
      try {
        const res = await deleteDomain({ id: domain.Id });
        if ('error' in res) {
          showNotification({
            title: `${(res as any)?.error?.data}`,
            message: '',
            autoClose: true,
            color: 'red',
            style: { zIndex: 1003 }
          });
        } else {
          showNotification({
            title: t('domains.deleteSuccess'),
            message: '',
            autoClose: true,
            color: 'green',
            style: { zIndex: 1003 }
          });
          setDeleteStep(false);
          if (handleChange) {
            handleChange();
          }
          setDomainDetails(domain);
        }
      } catch (err: any) {
        console.warn(t('domains.deleteError'), err);
      }
    }
  };

  const handleCloseModal = () => {
    setDeleteStep(false);
  };

  return (
    <>
      <Box mb="1rem" p="24px">
        <Text mb="1rem" fz="md">
          {t('domains.deleteWarning')}
        </Text>
        <Text fz="md">
          {t('domains.confirmDelete')} <b>{domain && domain.Domain}</b>?
        </Text>
      </Box>
      <Controls>
        <Group grow>
          <Button color="red" onClick={handleDelete}>
            {t('delete')}
          </Button>
          <UnstyledButton className={classes.cancelBtn} onClick={handleCloseModal}>
            {t('cancel')}
          </UnstyledButton>
        </Group>
      </Controls>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
    </>
  );
};

export default DeleteDomain;
