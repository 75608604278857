import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%'
  },

  title: {
    fontSize: '16px',
    marginBottom: theme.spacing.md
  },

  groupButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },

  control: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing.md
  },

  addBtn: {
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,

    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  },

  header: {
    fontSize: '14px',
    marginBottom: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  description: {
    fontSize: '14px',
    color: '#C01048',
    marginRight: theme.spacing.xs,
    cursor: 'pointer'
  },

  emptyData: {
    fontSize: '14px',
    color: theme.colors.brandGray[0]
  }
}));
