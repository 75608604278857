import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  content: {
    marginTop: theme.spacing.sm,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  }
}));
