import { FC, useState } from 'react';
import { useForm, FormErrors } from '@mantine/form';
import { Box, Button, SimpleGrid, UnstyledButton, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { useStyles } from './styles';
import { themeColors } from '@/theme/colors';
import { SelectWithPagination } from '@/containers/pages/filials/components/select';
import { SelectUserModal } from '@components/modal/select-user';
import { SelectGroupSimpleModal } from '@components/modal/select-group-simple';
import { showNotification } from '@mantine/notifications';
import { IUserTableData } from '@/entities/admin-app/users';
import { useAddRolesMutation } from '@/entities/admin-app/roles/api';
import { Badges } from '../badges';
import { transformObjectIfKeyIsNull } from '@/containers/pages/roles/utils';
import { useTranslation } from 'react-i18next';
import { getValidateErrors } from '@/lib/utils/validation';
import { validateCreateRolesSchema } from '@/containers/pages/roles/components/schemes/validate';

interface IRolesCreateForms {
  close: () => void;
}

interface ICreateRole {
  Name: string;
  Customer: {
    Id: null;
  };
  Parent: {
    Id: null;
  };
  Owner: {
    Id: null;
  };
}
export interface IBadges {
  label: string;
}
export const RolesCreateForm: FC<IRolesCreateForms> = ({ close }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState<IUserTableData | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<IBadges | null>(null);

  const [createRoles] = useAddRolesMutation();

  const closeModals = () => {
    setIsUserModalOpen(false);
    setIsRoleModalOpen(false);
  };

  const handleConfirmSelectUserModal = async () => {
    if (!selectedUser) {
      return;
    }
    form.setFieldValue('Owner.Id', selectedUser?.Id);
    closeModals();
  };

  const handleRemoveRoles = () => {
    setSelectedRoles(null);
    form.setFieldValue('Parent.Id', null);
  };

  const handleRemoveUser = () => {
    setSelectedUser(null);
    form.setFieldValue('Owner.Id', null);
  };

  const setGroupId = async (id: number) => {
    form.setFieldValue('Parent.Id', id);
    closeModals();
  };

  const setGroup = async (group: IBadges) => {
    setSelectedRoles(group);
  };

  const form = useForm({
    initialValues: {
      Name: '',
      Customer: {
        Id: null
      },
      Parent: {
        Id: null
      },
      Owner: {
        Id: null
      }
      //Type: 0,
    },
    validateInputOnChange: true,
    validate: (values: ICreateRole): FormErrors =>
      getValidateErrors(validateCreateRolesSchema, values)
  });

  const isDisabled = Object.keys(form.errors).length !== 0 || !form.isDirty();

  const handleSubmit = async (values: any) => {
    const formatted = transformObjectIfKeyIsNull(JSON.parse(JSON.stringify(values)));
    try {
      const res = await createRoles(formatted);
      if (!Object.keys(res).includes('error')) {
        showNotification({
          title: t('roles.roleAdded'),
          message: '',
          autoClose: true,
          color: 'green'
        });
        handleClose();
      } else {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red'
        });
      }
    } catch (err: any) {
      console.warn('Create roles ERROR', err);
    }
  };

  const handleClose = () => {
    close();
    form.reset();
  };

  return (
    <form className={classes.modalRoot} onSubmit={form.onSubmit(handleSubmit)}>
      <Box className={classes.modalContainer}>
        <SimpleGrid cols={1} w="100%">
          <TextInput
            withAsterisk
            label={t('roles.name')}
            placeholder=""
            {...form.getInputProps('Name')}
          />
          <SelectWithPagination
            form={form}
            fieldName="Customer.Id"
            label={t('roles.addFilial')}
            placeholder={t('choose')}
            list={[]}
            defaultValue={form.getInputProps('Customer.Id').value || null}
            value={form.getInputProps('Customer.Id').value}
          />

          <SimpleGrid cols={1} spacing="xs">
            <Button
              className={classes.addButton}
              size="sm"
              variant="outline"
              rightIcon={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => setIsRoleModalOpen(true)}
            >
              {t('roles.addParrentRole')}
            </Button>
            {selectedRoles && (
              <Box>
                <Badges label={selectedRoles?.label} onRemove={handleRemoveRoles} />
              </Box>
            )}
          </SimpleGrid>
          <SimpleGrid cols={1} spacing="xs">
            <Button
              className={classes.addButton}
              size="sm"
              variant="outline"
              rightIcon={<IconPlus color={themeColors.brandGray[0]} />}
              onClick={() => setIsUserModalOpen(true)}
            >
              {t('roles.addRoleManager')}
            </Button>
            {selectedUser && (
              <Box>
                <Badges label={selectedUser.Name} onRemove={handleRemoveUser} />
              </Box>
            )}
          </SimpleGrid>
        </SimpleGrid>
      </Box>

      <Box className={classes.modalFooter}>
        <Button type="submit" size="sm" radius={8} w="49%" disabled={isDisabled}>
          {t('save')}
        </Button>

        <UnstyledButton
          type="button"
          ml={8}
          className={classes.modalCancelBtn}
          w="49%"
          onClick={close}
        >
          {t('cancel')}
        </UnstyledButton>
      </Box>
      <SelectGroupSimpleModal
        onClose={closeModals}
        isOpened={isRoleModalOpen}
        title={t('roles.addParrentRole')}
        setGroupId={setGroupId}
        setGroup={setGroup}
      />

      <SelectUserModal
        multipleUsers={false}
        isOpened={isUserModalOpen}
        title={t('roles.addRoleManager')}
        onClose={closeModals}
        setSelectedUsers={setSelectedUser}
        userId={selectedUser?.Id}
        controlSection
        controlSectionProps={{
          confirmProps: {
            btnName: t('add'),
            fn: handleConfirmSelectUserModal
          },
          cancelProps: {
            btnName: t('cancel'),
            fn: closeModals
          }
        }}
      />
    </form>
  );
};
