import { IFiltersEnumType } from '@/types/enums';
export const BASE_URL = '/api';
export const ssoRedirectLink = '/api/v1/OAuth20/Code?type=mail';
export const kerberosRedirectLink = '/api/v2/auth/kerberos';
export const filtersLabels: Partial<Record<IFiltersEnumType, string>> = {
  1: 'Роль',
  2: 'Статус',
  3: 'Подразделения',
  4: 'Должность',
  5: 'Руководитель',
  6: 'Тип роли',
  7: 'Авторизация',
  8: 'Пол',
  9: 'Email',
  10: 'Местоположение',
  11: 'Дата рождения',
  12: 'Дата создания',
  13: 'Последний вход',
  14: 'Политика квот',
  15: 'Занято места, Мб',
  16: 'Пользователь',
  17: 'Действие'
};
