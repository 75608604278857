import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  popover: {
    padding: theme.spacing.xs
  },
  itemMenu: {
    padding: theme.spacing.xs,
    borderRadius: '8px',
    fontSize: '14px'
  }
}));
