import React, { createContext, Dispatch, FC, SetStateAction } from 'react';
import { HomePanel } from './home';
import { MoreInfoPanel } from './more-info';
import { ActiveConnectionsPanel } from './active-connections';
import { DataStorageQuotaPanel } from './data-storage-quota';
import { PanelsEnum } from '@/types/enums/user';
import { useGetUserInfoQuery } from '@/entities/admin-app/user/api';
import { IRowData } from '@components/simple-table/types';
import { IUserInfo } from '@/entities/admin-app/user';

export interface IUserInfoMainContext {
  selectedUserId: number;
  setActivePanel: Dispatch<SetStateAction<PanelsEnum>>;
  activePanel: PanelsEnum;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
  userInfoCommon?: IUserInfo;
}

export const UserInfoMainContext = createContext<IUserInfoMainContext>({} as IUserInfoMainContext);

export interface IPanelsProps {
  selectedUserId: number;
  setActivePanel: Dispatch<SetStateAction<PanelsEnum>>;
  activePanel: PanelsEnum;
  setRowData: Dispatch<SetStateAction<IRowData[]>>;
}

export const Panels: FC<IPanelsProps> = ({
  selectedUserId,
  setActivePanel,
  activePanel,
  setRowData
}) => {
  const { data } = useGetUserInfoQuery(selectedUserId);

  const initialValues: IUserInfoMainContext = {
    selectedUserId,
    setActivePanel,
    activePanel,
    setRowData,
    userInfoCommon: data
  };

  return (
    <UserInfoMainContext.Provider value={initialValues}>
      {activePanel === PanelsEnum.generalInformation && <HomePanel />}
      {activePanel === PanelsEnum.additionalInformation && <MoreInfoPanel />}
      {/*{activePanel === PanelsEnum.groups && <GroupsPanel />}*/}
      {/*{activePanel === PanelsEnum.notes && <NotesPanel />}*/}
      {/*{activePanel === PanelsEnum.subscriptions && <SubscriptionsPanel />}*/}
      {activePanel === PanelsEnum.activeConnections && <ActiveConnectionsPanel />}
      {activePanel === PanelsEnum.dataStorageQuota && <DataStorageQuotaPanel />}
    </UserInfoMainContext.Provider>
  );
};
