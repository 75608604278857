import React from 'react';
import { Group, Box, Button, SimpleGrid } from '@mantine/core';
import { TFunction } from 'i18next';
import iconArrow from '@assets/icon-arrow.svg';
import { useMediaQuery } from '@mantine/hooks';

interface RegistrationControlsProps {
  active: number;
  nextStep: () => void;
  prevStep: () => void;
  handleClickButton: () => void;
  serviceEmail: boolean;
  classes: any;
  t: TFunction;
  isSubmitting: boolean;
  isSaas?: boolean;
}

export const RegistrationControls = ({
  active,
  nextStep,
  prevStep,
  handleClickButton,
  serviceEmail,
  classes,
  t,
  isSubmitting,
  isSaas
}: RegistrationControlsProps) => {
  const isMobile = useMediaQuery('(max-width: 576px)');

  return (
    <Group position="apart" className={classes.footerForm}>
      {active === 0 && (
        <Button size="lg" w="100%" onClick={nextStep}>
          {t('register.continue')}
        </Button>
      )}

      {active === 1 && (
        <Box sx={{ width: '100%' }}>
          <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
            <Button
              size="lg"
              w="100%"
              className={classes.footerFormPrevButton}
              variant="default"
              onClick={prevStep}
            >
              <img src={iconArrow} alt="icon" className={classes.iconArrow} />
              {t('register.back')}
            </Button>
            <Button size="lg" w="100%" onClick={nextStep}>
              {t('register.continue')}
            </Button>
          </SimpleGrid>
          {isSaas ? (
            <Button
              onClick={handleClickButton}
              className={classes.mailButton}
              variant="subtle"
              size="lg"
              w="100%"
            >
              {serviceEmail ? t('register.useExistingEmail') : t('register.createNewEmail')}
            </Button>
          ) : null}
        </Box>
      )}

      {active === 2 && (
        <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
          <Button
            size="lg"
            w="100%"
            className={classes.footerFormPrevButton}
            variant="default"
            onClick={prevStep}
          >
            <img src={iconArrow} alt="icon" className={classes.iconArrow} />
            {t('register.back')}
          </Button>
          <Button
            size="lg"
            w="100%"
            type="submit"
            disabled={isSubmitting}
            className={classes.registerButton}
          >
            {t('register.register')}
          </Button>
        </SimpleGrid>
      )}
    </Group>
  );
};
