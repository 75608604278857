import { Box } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { themeColors } from '@/theme/colors';

export const useConfirmCancelForm = () => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate('/users');
  };

  const openDeleteAvatar = () => {
    openContextModal({
      modal: 'context',
      centered: true,
      innerProps: {
        title: 'Предупреждение',
        modalBody: (
          <Box mb={24}>
            Вы не сохранили информацию о новом пользователе. Вы уверены, что не хотите сохранить
            измения?
          </Box>
        ),
        confirmProps: {
          color: 'brandRed.1',
          sx: { backgroundColor: themeColors.brandRed[1], color: 'white', width: '49%' }
        },
        cancelProps: {
          sx: {
            backgroundColor: themeColors.brandGray[1],
            color: 'black',
            width: '49%'
          }
        },
        labels: { confirm: 'Продолжить', cancel: 'Отмена' },
        onConfirm: () => handleConfirm()
      }
    });
  };

  return openDeleteAvatar;
};
