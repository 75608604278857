import { createStyles } from '@mantine/core';
export interface ISearchStylesParams {
  fullSize?: boolean;
}

export const useStyles = createStyles((theme, { fullSize }: ISearchStylesParams) => ({
  search: {
    width: fullSize ? '100%' : 'auto',

    '& input': {
      borderRadius: '8px',
      border: 'none'
    },

    '& .mantine-Input-input[data-with-icon]': {
      paddingLeft: '56px'
    },

    '& .mantine-Input-icon': {
      marginLeft: theme.spacing.sm
    },

    '&:hover': {
      '& .mantine-Search-clearIcon': {
        display: 'inherit'
      }
    }
  },
  clearIcon: {
    cursor: 'pointer',
    marginRight: theme.spacing.sm,
    color: '#98A2B3',
    display: 'none'
  }
}));
