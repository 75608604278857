import { ActionIcon, Badge, Box, Menu, UnstyledButton } from '@mantine/core';
import { FC } from 'react';
import { IconBackspace, IconChevronDown } from '@tabler/icons';
import { themeColors } from '@/theme/colors';
import { useStyles } from './styles';
import { SearchFilter } from '@components/filters/search-filter';
import { IFilterMenu, IChangeFilterData } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { useGetFilterListItemsQuery } from '@/entities/admin-app/roles/api';

interface IFilterListProps {
  activeFilters: Record<number, number[] | Date[]>;
  handleFilterChange: (data: IChangeFilterData) => void;
  handleFilterReset: (value: IFiltersEnumType) => void;
  handleResetFilters: () => void;
  filtersMenuList: IFilterMenu[];
}

export const FilterList: FC<IFilterListProps> = ({
  activeFilters,
  handleFilterReset,
  filtersMenuList,
  // handleResetFilters,
  handleFilterChange
}) => {
  const { classes } = useStyles();
  const slicedFilters = [...filtersMenuList.slice(0, 4)];
  if (!filtersMenuList) {
    return null;
  }

  return (
    <Box className={classes.filtersListWrapper}>
      {slicedFilters.map((filterMenu, index) => {
        const activeIds = activeFilters[filterMenu?.Type] as number[];
        return (
          <Menu width="auto" zIndex={400} position="bottom-start" key={filterMenu?.label + index}>
            <Menu.Target>
              <Badge
                className={classes.filterHeader}
                rightSection={
                  activeIds?.length ? (
                    <Box className={classes.badgeContainer}>
                      <Badge className={classes.badge} variant="filled">
                        {activeIds?.length}
                      </Badge>

                      <ActionIcon size={24} onClick={() => handleFilterReset(filterMenu.Type)}>
                        <IconBackspace size={16} color={themeColors.brandGray[0]} />
                      </ActionIcon>
                    </Box>
                  ) : null
                }
              >
                <UnstyledButton className={classes.menuButton}>
                  {filterMenu?.label || 'Статус'} <IconChevronDown size={18} />
                </UnstyledButton>
              </Badge>
            </Menu.Target>
            <Menu.Dropdown className={classes.dropdownContainer}>
              {/* TO DO: FIX: src/components/navbar/search-filter/index.tsx  - call api from users */}
              <SearchFilter
                handleFilterReset={handleFilterReset}
                activeIds={activeIds}
                filterType={filterMenu?.Type}
                label={filterMenu?.label}
                list={filterMenu?.Items}
                hideCollapse={true}
                handleFilterChange={handleFilterChange}
                handleLoadData={useGetFilterListItemsQuery}
              />
            </Menu.Dropdown>
          </Menu>
        );
      })}
    </Box>
  );
};
