import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundColor: theme.colors.backgroundGray[0],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(/src/assets/img/login-bg.jpeg)`
  },

  form: {
    // borderRight: `1px solid ${
    //   theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    // }`,
    position: 'relative',
    maxWidth: 450,
    width: '100%',
    padding: 40,
    borderRadius: theme.spacing.xs,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%'
    }
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`
  },

  logo: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    width: 120,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  responsiveButton: {
    padding: 0,
    [`@media (max-width: 475px)`]: {
      width: '100%'
    }
  },

  responsiveInput: {
    [`@media (max-width: 475px)`]: {
      width: '100%'
    }
  },

  controls: {
    [`@media (max-width: 475px)`]: {
      width: '100%',
      span: {
        fontSize: '12px'
      },
      'button, a': {
        padding: 0
      }
    },
    '& .mantine-Input-input': {
      gap: '13px'
    },
    '& .mantine-InputWrapper-error': {
      marginBottom: '10px',
      marginTop: '10px',
      fontSize: '14px',
      position: 'relative'
    }
  },
  itemsGroup: {
    justifyContent: 'space-between',
    [`@media (max-width: 475px)`]: {
      justifyContent: 'end'
    }
  },
  itemLink: {
    justifyContent: 'end'
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
    marginTop: '-10px'
  },
  subtitle: {
    color: theme.colors.gray[6],
    [`@media (max-width: 475px)`]: {
      fontSize: '12px'
    }
  },
  link: {
    padding: 0,
    fontWeight: 400,
    marginLeft: '5px',
    fontSize: '16px',
    height: 'auto',
    [`@media (max-width: 475px)`]: {
      fontSize: '12px'
    },
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },
  footerControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [`@media (min-width: 475px)`]: {
      flexWrap: 'nowrap'
    }
  },
  subtleButton: {
    fontWeight: 100,
    padding: '0!important',
    marginTop: '-10px',
    alignSelf: 'flex-start',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },

  mainWrapper: {
    padding: 10
  }
}));
