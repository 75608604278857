import { useState, useEffect } from 'react';

import { useGetDefaultAuthActionsFiltersQuery } from '@/entities/admin-app/statistics/api';
import { IFilterMenu } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { filtersLabels } from '@/entities/admin-app/constants';
import { useTranslation } from 'react-i18next';

export const useGetAuthActionsFilters = () => {
  const { t } = useTranslation();
  const { data: defaultFiltersData, isSuccess: defaultFiltersSuccess } =
    useGetDefaultAuthActionsFiltersQuery(10);

  const [filtersMenuList, setFiltersMenuList] = useState<IFilterMenu[]>([]);

  useEffect(() => {
    if (!(defaultFiltersData && defaultFiltersSuccess)) {
      return;
    }
    const convertDefaultFilters: IFilterMenu[] = defaultFiltersData
      .map((item) => ({
        ...item,
        label: filtersLabels[item.Type] || t('title'),
        Items: item.Items?.map((filterItem) => ({
          ...filterItem,
          Count: undefined,
          checked: false
        })),
        hideSearch: false
      }))
      .sort((a, b) => a.Type - b.Type)
      .concat({
        label: t('statistics.date_action'),
        Type: IFiltersEnumType.DateOfCreateFilter,
        Items: [],
        hideSearch: true
        // Count: undefined,
        // checked: false
      });
    setFiltersMenuList(convertDefaultFilters);
  }, [defaultFiltersData, defaultFiltersSuccess]);

  return { filtersMenuList };
};
