import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stepper, Box } from '@mantine/core';
import { useCreateClientMutation } from '@/entities/admin-app/register/api';
import { useLazyCheckEmailExistsQuery } from '@/entities/admin-app/auth/api';
import { useForm, zodResolver } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useStyles } from '../styles';

import { InfoStep } from '@/containers/pages/register/components/InfoStep';
import { EmailStep } from '@/containers/pages/register/components/EmailStep';
import { PasswordStep } from '@/containers/pages/register/components/PasswordStep';
import { RegistrationControls } from '@/containers/pages/register/components/RegistrationConstrols';
import { useGeneratePolicy } from '@hooks/password/useGeneratePolicy';
import { IPasswordSecurity } from '@/entities/admin-app/filials';
import { ICheckExistRegisterCodeResponse } from '@/entities/admin-app/auth';
import { generatePasswordRegExp } from '@/containers/pages/user/components/sections/general-info/password/utils';
import { IQueryRegister } from '@/entities/admin-app/register';
import { isSaaS } from '@/lib/utils/access';
import { useAppSelector } from '@hooks/redux/redux';
import { limitsLengthRegistrationFields } from '@/containers/pages/register/util';
import { universalNotification } from '@/lib/utils/notification';

interface FormValues {
  FirstName: string;
  Surname: string;
  Email: string;
  Password: string;
  Company: string;
  AgreementsConfirmedRule: boolean;
  AgreementsConfirmedInfo: boolean;
}

interface IRegistrationProps {
  passwordStrengthOptions?: IPasswordSecurity;
  invitationData?: ICheckExistRegisterCodeResponse;
  invitationCode?: string;
}

export const RegistrationForm = ({
  passwordStrengthOptions,
  invitationData,
  invitationCode
}: IRegistrationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const [createClient] = useCreateClientMutation();
  const [checkEmailExists] = useLazyCheckEmailExistsQuery();
  const { classes } = useStyles();
  const isSaas = isSaaS(Settings);
  const [active, setActive] = useState(0);
  const [serviceEmail, setServiceEmail] = useState(isSaas);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const EMAIL_DOMAIN = '@r7-space.ru';
  const passwordRegexp = generatePasswordRegExp({ options: passwordStrengthOptions });
  const generatePasswordPolicyString = useGeneratePolicy();

  const schema = z
    .object({
      FirstName: z.string().min(1, { message: t('register.nameShort') }),
      Surname: z.string().min(1, { message: t('register.surnameShort') }),
      Company: z
        .string()
        .min(1, { message: t('register.companyShort') })
        .max(limitsLengthRegistrationFields.Company, {
          message: t('validation.maxLengthSymbolsWithCount.symbols', {
            count: limitsLengthRegistrationFields.Company
          })
        }),
      Email: z.string().refine(
        (data) => {
          if (serviceEmail) {
            return z
              .string()
              .email({ message: t('register.incorrectEmail') })
              .safeParse(data + EMAIL_DOMAIN).success;
          } else {
            return z
              .string()
              .email({ message: t('register.incorrectEmail') })
              .safeParse(data).success;
          }
        },
        {
          message: t('register.incorrectEmail')
        }
      ),
      Password: z
        .string()
        .regex(passwordRegexp, { message: generatePasswordPolicyString(passwordStrengthOptions) }),
      AgreementsConfirmedRule: z
        .boolean()
        .refine((value) => value, { message: t('register.agreementsConfirmedRule') })
    })
    .refine((data) => data.FirstName.length > 0, {
      message: t('register.requiredField'),
      path: ['FirstName']
    })
    .refine((data) => data.Surname.length > 0, {
      message: t('register.requiredField'),
      path: ['Surname']
    })
    .refine((data) => data.Email.length > 0, {
      message: t('register.requiredField'),
      path: ['Email']
    })
    .refine((data) => data.Password.length > 0, {
      message: t('register.requiredField'),
      path: ['Password']
    });

  const form = useForm<FormValues>({
    validate: zodResolver(schema),
    initialValues: {
      FirstName: '',
      Surname: '',
      Email: '',
      Password: '',
      Company: '',
      AgreementsConfirmedInfo: false,
      AgreementsConfirmedRule: false
    },
    validateInputOnChange: false
  });

  const appendDomain = (email: string, isServiceEmail: boolean) => {
    return isServiceEmail ? `${email}${EMAIL_DOMAIN}` : email;
  };

  const nextStep = async () => {
    const fieldsToValidate = getFieldsToValidate(active);

    let allFieldsValid = true;

    fieldsToValidate.forEach((field) => {
      const error = form.validateField(field);
      if (error.hasError) {
        allFieldsValid = false;
      }
    });

    if (allFieldsValid) {
      if (active === 1) {
        const emailToCheck = appendDomain(form.values.Email, serviceEmail);

        try {
          const result = await checkEmailExists({ email: emailToCheck }).unwrap();

          if (result?.ExistsUser) {
            showNotification({
              title: t('requestsErrors.error'),
              message: t('Такой адрес уже занят, попробуйте другой'),
              autoClose: true,
              color: 'red'
            });
            return;
          }
        } catch (e) {
          showNotification({
            title: t('requestsErrors.error'),
            message: t('Ошибка при проверке почты'),
            autoClose: true,
            color: 'red'
          });
          return;
        }
      }
      setActive((current) => (current < 2 ? current + 1 : current));
    }
  };

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  function getFieldsToValidate(step: number): string[] {
    const stepFields: Record<number, string[]> = {
      0: ['FirstName', 'Surname', 'Company'],
      1: ['Email'],
      2: ['Password']
    };
    return stepFields[step] || [];
  }

  const handleClickButton = () => {
    setServiceEmail((current) => !current);
    form.setFieldValue('Email', '');
  };

  const handleChange = (event: any) => {
    const { value } = event.currentTarget;
    if (!value.includes('@')) {
      form.setFieldValue('Email', value);
    }
  };

  useEffect(() => {
    if (invitationData) {
      form.setFieldValue('Company', invitationData.Company);
    }
  }, [invitationData]);

  const handleSubmit = async (values: FormValues) => {
    try {
      const clientDat: IQueryRegister = {
        Alias: null,
        Domain: '',
        Company: values.Company,
        Owner: {
          FirstName: values.FirstName,
          Surname: values.Surname,
          Email: appendDomain(values.Email, serviceEmail),
          Password: values.Password,
          AgreementsConfirmedInfo: values.AgreementsConfirmedInfo,
          AgreementsConfirmedRule: values.AgreementsConfirmedRule
        }
      };

      if (invitationCode && invitationCode.length) {
        clientDat.Code = invitationCode;
      }

      setIsSubmitting(true);
      const res = await createClient(clientDat);
      if ('error' in res) {
        universalNotification({
          error: res.error,
          isError: true,
          isSuccess: false
        });
        setIsSubmitting(false);
      } else {
        showNotification({
          title: `Регистрация прошла успешно!`,
          message: '',
          autoClose: true,
          color: 'green',
          style: { zIndex: 1003 }
        });
        navigate('/login');
        setIsSubmitting(false);
      }
    } catch (err: any) {
      console.warn('Ошибка регистрации', err);
      setIsSubmitting(false);
    }
  };

  const handleChangePassword = (password: string) => {
    form.setFieldValue('Password', password.replace(/\s+/g, ''));
  };

  return (
    <Box className={classes.controls} component="form" onSubmit={form.onSubmit(handleSubmit)}>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step withIcon={false}>
          <InfoStep
            form={form}
            classes={classes}
            t={t}
            isDisableCompany={!!invitationData?.Company.length}
          />
        </Stepper.Step>
        <Stepper.Step withIcon={false}>
          <EmailStep
            form={form}
            serviceEmail={serviceEmail}
            handleChange={handleChange}
            EMAIL_DOMAIN={EMAIL_DOMAIN}
            classes={classes}
            t={t}
          />
        </Stepper.Step>
        <Stepper.Step withIcon={false}>
          <PasswordStep
            form={form}
            handleChangePassword={handleChangePassword}
            classes={classes}
            t={t}
          />
        </Stepper.Step>
      </Stepper>
      <RegistrationControls
        isSaas={isSaas}
        active={active}
        nextStep={nextStep}
        prevStep={prevStep}
        handleClickButton={handleClickButton}
        serviceEmail={serviceEmail}
        classes={classes}
        t={t}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};
