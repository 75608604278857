import { z } from 'zod';
import {
  emailRegExp,
  emailRegExpErrorText,
  genderRegExpErrorText,
  phoneRegExp,
  phoneRegExpErrorText
} from '@/lib/utils/validation';
import i18n from '@/entities/i18next';
import { IAdditionalEmailCredentials } from '@/entities/admin-app/user';

export const validateSchemeStatus = z.number({
  invalid_type_error: i18n.t('validation.selectUserStatus'),
  required_error: i18n.t('validation.statusRequired')
});

export const validateSchemeCustomer = z.object({
  Id: z
    .number({
      invalid_type_error: i18n.t('validation.selectDepartment'),
      required_error: i18n.t('validation.divisionRequired')
    })
    .positive({
      message: i18n.t('validation.selectDepartment')
    })
});

export const validateSchemePositions = z
  .array(
    z.object({
      Position: z.object({
        Name: z
          .string()
          .max(64, {
            message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 64 })
          })
          .refine((data) => /\S/.test(data), {
            message: i18n.t('validation.enterPosition')
          })
      })
    })
  )
  .nullable();

export const validateSchemeDataStorageQuota = z.object({
  Id: z
    .number({
      invalid_type_error: i18n.t('validation.selectQuota'),
      required_error: i18n.t('validation.quotaMandatory')
    })
    .optional()
    .nullable()
});

export const validateEmailScheme = z.object({
  Email: z
    .string()
    .regex(emailRegExp, { message: emailRegExpErrorText })
    .or(z.literal(''))
    .optional()
    .nullable()
});

function validateAdditionalEmailNotEqual(data?: IAdditionalEmailCredentials) {
  if (!data?.Email || !data?.AdditionalEmail) {
    return true;
  }
  return data?.AdditionalEmail !== data?.Email;
}
export const validateAdditionalEmailScheme = z
  .object({
    AdditionalEmail: z
      .string()
      .regex(emailRegExp, { message: emailRegExpErrorText })
      .or(z.literal(''))
      .optional()
      .nullable(),
    Email: z.string().or(z.literal('')).optional().nullable()
  })
  .refine(validateAdditionalEmailNotEqual, {
    message: i18n.t('validation.emailsShouldNotBeTheSame'),
    path: ['AdditionalEmail']
  });
export const validateLoginScheme = z.object({
  Login: z
    .string()
    .min(1, { message: i18n.t('validation.enterLogin') })
    .refine((data) => !/\s/.test(data), {
      message: i18n.t('validation.loginWithoutSpaces')
    })
});

export const isSaasScheme = z.object({
  isSaaS: z.literal(true),
  Email: z
    .string()
    .regex(emailRegExp, { message: emailRegExpErrorText })
    .min(1, { message: 'Введите e-mail' })
});

export const noSaasScheme = z.object({
  isSaaS: z.literal(false),
  Email: z
    .string()
    .regex(emailRegExp, { message: emailRegExpErrorText })
    .or(z.literal(''))
    .optional()
    .nullable()
});

export const validateCredentialsNoSaas = z.object({
  isSaas: z.literal(false),
  Email: z
    .string()
    .regex(emailRegExp, { message: emailRegExpErrorText })
    .or(z.literal(''))
    .optional()
    .nullable(),
  Login: z
    .string()
    .min(1, { message: i18n.t('validation.enterLogin') })
    .refine((data) => !/\s/.test(data), {
      message: i18n.t('validation.loginWithoutSpaces')
    })
});

export const validateCredentialsSaas = z.object({
  isSaas: z.literal(true),
  Email: z
    .string()
    .regex(emailRegExp, { message: emailRegExpErrorText })
    .min(1, { message: 'Введите e-mail' }),
  Login: z.string().optional().nullable()
});

export const validateSchemeCredentials = z.union([
  validateCredentialsNoSaas,
  validateCredentialsSaas
]);

export const validateSchemeEditUserInfo = z.object({
  SurName: z
    .string()
    .max(85, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 85 }) })
    .refine((data) => /\S/.test(data), {
      message: i18n.t('validation.enterLastName')
    }),
  FirstName: z
    .string()
    .max(85, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 85 }) })
    .refine((data) => /\S/.test(data), {
      message: i18n.t('validation.enterName')
    }),
  MiddleName: z
    .string()
    .max(85, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 85 }) })
    .optional()
    .or(z.literal(''))
    .nullable(),
  Gender: z
    .number()
    .min(1, { message: genderRegExpErrorText })
    .or(z.number().default(0))
    .optional(),
  BirthDate: z
    .preprocess(
      (arg) => (typeof arg === 'string' ? new Date(arg) : ' '),
      z
        .date({ invalid_type_error: i18n.t('validation.indicateBirthday') })
        .min(new Date('1900-01-01'), { message: i18n.t('validation.enteredDateSmall') })
        .max(new Date(), { message: i18n.t('validation.dateLessCurrent') })
        .or(z.string().optional())
        .nullable()
    )
    .nullable(),
  Address: z
    .string()
    .max(255, { message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', { count: 255 }) })
    .optional()
    .nullable(),
  Phone: z
    .string()
    .regex(phoneRegExp, { message: phoneRegExpErrorText })
    .or(z.literal(''))
    .optional()
    .nullable(),
  AdditionalPhone: z
    .string()
    .regex(/^[0-9]*$/, { message: i18n.t('validation.extensionPhoneContainNumbers') })
    .or(z.literal(''))
    .optional()
    .nullable()
});
