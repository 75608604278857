import { Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export function NoDataText() {
  const { t } = useTranslation();

  return (
    <Text weight={500} align="center" color="gray.6">
      {t('noData')}
    </Text>
  );
}
