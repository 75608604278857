import { FC, RefObject, SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mantine/core';

import {
  driveInfoSections,
  emailInfoSections,
  calendarInfoSections,
  drawInfoSections,
  contactsInfoSections
} from '@/mock-data/navbar-menu';
import { securityInfoSections } from '../../utiils';
import { FilialInfoPanelsEnum } from '@/types/enums/filials';
import {
  DriveInfoPanelsEnum,
  EmailInfoPanelsEnum,
  CalendarInfoPanelsEnum,
  DrawInfoPanelsEnum,
  ContactsInfoPanelsEnum
} from '@/types/enums/modules';
import { useStyles } from './styles';

interface IInfoSectionProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const InfoSection: FC<IInfoSectionProps> = ({ containerRef }) => {
  const location = useLocation();
  const { classes, cx } = useStyles();

  type sectionsType =
    | typeof driveInfoSections
    | typeof emailInfoSections
    | typeof calendarInfoSections
    | typeof drawInfoSections
    | typeof securityInfoSections
    | typeof contactsInfoSections;
  type sectionType =
    | DriveInfoPanelsEnum
    | EmailInfoPanelsEnum
    | CalendarInfoPanelsEnum
    | DrawInfoPanelsEnum
    | ContactsInfoPanelsEnum
    | FilialInfoPanelsEnum;

  let sections: sectionsType | never[] = [];
  switch (location.pathname.split('/').pop()) {
    case 'security':
      sections = securityInfoSections;
      break;

    case 'email':
      sections = emailInfoSections;
      break;

    case 'calendar':
      sections = calendarInfoSections;
      break;

    case 'draw':
      sections = drawInfoSections;
      break;
    case 'contacts':
      sections = contactsInfoSections;
      break;
    case 'drive':
      sections = driveInfoSections;
      break;
  }

  const [activeSection, setActiveSection] = useState<sectionType | null>(null);
  useEffect(() => {
    setActiveSection(sections[0]?.value || null);
  }, [sections]);

  const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    const { value } = event.currentTarget.dataset;
    if (!value) {
      return;
    }

    setActiveSection(value as sectionType);

    const targetElement = containerRef.current?.querySelector(`[data-section="${value}"]`);

    if (targetElement) {
      const headerHeight = 80;
      const scrollPosition =
        targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, []);

  return (
    <Box className={classes.itemBox}>
      {sections.map((item) => {
        return (
          <Box
            className={cx(classes.item, {
              [classes.itemActive]: activeSection === item?.value
            })}
            onClick={handleClick}
            key={item?.value}
            data-value={item?.value}
          >
            {item?.label}
          </Box>
        );
      })}
    </Box>
  );
};
