import { FC } from 'react';
import { Avatar, Checkbox, Flex, Grid, Text } from '@mantine/core';
import { IRemoveUser } from '@/entities/admin-app/users';
import { TransferData } from '../transfer-data';
import { useTranslation } from 'react-i18next';

interface IListItem {
  isSelectedMultiple?: boolean;
  value?: IRemoveUser;
  onChange: (user: IRemoveUser) => void;
}

export const ListItem: FC<IListItem> = ({ isSelectedMultiple, value, onChange }) => {
  const { t } = useTranslation();
  if (!value) {
    return null;
  }

  const { isChecked, email, firstName, middleName, isNotAcceptable } = value;

  const handleCheckChange = () => {
    onChange({ ...value, isChecked: !value?.isChecked });
  };

  return (
    <Grid align="center" py={12} m={0}>
      <Grid.Col p={0} span={7}>
        <Flex align="center" direction="row" gap="sm">
          {isSelectedMultiple && (
            <Checkbox
              disabled={isNotAcceptable}
              checked={isNotAcceptable ? false : isChecked}
              size="xs"
              onChange={handleCheckChange}
            />
          )}
          <Avatar size={40} radius={40} />
          <Flex direction="column">
            <Text fz="sm" fw="bold">
              {firstName} {middleName}
            </Text>
            <Text fz="xs" color="brandGray.0">
              {email}
            </Text>
          </Flex>
        </Flex>
      </Grid.Col>
      <Grid.Col p={0} span={5}>
        {isNotAcceptable ? (
          <Text fz="xs" color="brandGray.0">
            {t('users.noRightsToDelete')}
          </Text>
        ) : (
          <TransferData
            userId={value.id}
            onChange={(transferUserId) => onChange({ ...value, transferUserId })}
          />
        )}
      </Grid.Col>
    </Grid>
  );
};
