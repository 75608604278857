import { useState, useEffect, useMemo } from 'react';
import { SelectItem } from '@mantine/core';
import { IQuota } from '@/entities/admin-app/quotas';
import { IFiltersEnumType } from '@/types/enums';
import { IFilterConditionItem } from '@/types/api';
import { useGetQuotasQuery } from '@/entities/admin-app/quotas/api';

export interface FilterProps {
  Count: number;
  Offset: number;
  FilterConditions?: IFilterConditionItem[];
  LoadUseCount: boolean;
}

export default function userLoadQuotas(Customer?: number | null, createUser?: boolean) {
  const [quotas, setQuotas] = useState<IQuota[]>([]);
  const [quotaKey, setQuotaKey] = useState<number>(0);
  const [defaultQuotaId, setDefaultQuotaId] = useState<string>('');

  const { data, isFetching, isError } = useGetQuotasQuery({
    Count: 10,
    Offset: 0,
    LoadUseCount: false,
    FilterConditions: Customer
      ? [
          {
            Type: IFiltersEnumType.FilialFilter,
            NumValues: [Customer]
          }
        ]
      : undefined
  });

  useEffect(() => {
    if (data) {
      setQuotas(data.Quotas);
      setQuotaKey(Math.random());

      if (data.DefaultItemId && createUser) {
        setDefaultQuotaId(data.DefaultItemId.toString());
      }
    }
  }, [data]);

  const list = useMemo(
    () =>
      Array.isArray(quotas)
        ? quotas?.map(
            (Item: IQuota) =>
              ({ label: String(Item.name), value: Item.id.toString() } as SelectItem)
          )
        : [],
    [quotas]
  );

  return { list, isFetching, isError, quotaKey, defaultQuotaId };
}
