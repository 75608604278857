import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    flexGrow: 1,
    // overflow: 'auto',
    width: `calc(100% - 580px)`,
    backgroundColor: theme.colors.backgroundGray[0],
    padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm}`,
    [theme.fn.smallerThan('lg')]: {
      width: '100%'
    },
    [theme.fn.smallerThan('md')]: {
      padding: '16px'
    },
    [theme.fn.largerThan('lg')]: {
      marginRight: 290
    },
    [theme.fn.largerThan('md')]: {
      marginLeft: 290
    }
  },

  section: {
    height: `calc(100vh - 80px)`
  },

  scrollContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: '8px',
    height: '100%',
    backgroundColor: theme.colors.backgroundGray[0]
  },

  headerMenu: {
    backgroundColor: 'white',
    padding: '10px 0 0 10px',
    [theme.fn.largerThan('md')]: {
      display: 'none'
    }
  },

  headerMenuBurger: {
    div: {
      height: '2px'
    }
  },
  fakeNavbar: {
    width: '290px',
    [theme.fn.smallerThan('lg')]: {
      display: 'none'
    }
  },
  simpleNavbar: {
    backgroundColor: theme.colors.backgroundGray[0],
    position: 'fixed',
    [theme.fn.smallerThan('md')]: {
      alignItems: 'flex-start',
      marginRight: theme.spacing.xs,
      marginLeft: theme.spacing.xs,
      backgroundColor: 'white',
      padding: '0'
    }
  }
}));
