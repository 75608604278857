import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    borderRadius: '8px',
    width: '100%',
    cursor: 'pointer',

    '& input, label': {
      cursor: 'pointer'
    },

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black
    },

    '& .mantine-Checkbox-root, .mantine-Checkbox-body, .mantine-Checkbox-labelWrapper': {
      width: '100%'
    }
  },

  counter: {
    fontSize: '12px',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black
  },

  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },

  checkbox: {
    cursor: 'pointer',
    wordBreak: 'break-word',
    maxWidth: '90%'
  },

  selectedGroup: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
    color: theme.colorScheme === 'dark' ? theme.white : theme.black
  }
}));
