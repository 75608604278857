import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Radio,
  Text,
  TextInput,
  PasswordInput,
  SimpleGrid,
  Checkbox,
  NumberInput,
  Textarea,
  Divider
} from '@mantine/core';
import { FormErrors, useForm } from '@mantine/form';
import { z } from 'zod';

import { useAppSelector } from '@hooks/redux/redux';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';

import { getValidateErrors } from '@/lib/utils/validation';

import { useStyles } from '../../../styles';
import { IconRefresh } from '@tabler/icons';
import { ldapSettingsSync } from '@/containers/pages/filials/hooks/LdapSettingsSync';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import i18n from '@/entities/i18next';

interface ILDAPForm {
  useLDAP: string;
  server: string;
  login: string;
  password: string;
  UseSsl: boolean;
  Port: number | null;
  DN: string;
  Domain: string;
  UserFilter: string;
  mapping: {
    principal_name: string;
    login: string;
    first_name: string;
    second_name: string;
    middle_name: string;
    email?: string | null;
    phone?: string | null;
    birthdate?: string | null;
    position?: string | null;
    organization?: string | null;
    city?: string | null;
    isDeleted?: string | null;
  };
  GroupEnabled: string;
  GroupDn: string;
  GroupFilter: string;
  GroupMapping: {
    Description?: string | null;
    Name: string;
    Uid: string;
    Member: string;
  };
}

const portSchema = z
  .number({
    required_error: i18n.t('filials.authorization.addPort'),
    invalid_type_error: i18n.t('filials.authorization.numberPort')
  })
  .positive({ message: i18n.t('errors.positive') })
  .lt(65536, { message: i18n.t('errors.maxValue') });

const noAuthSchema = z.object({
  useLDAP: z.literal('false'),
  server: z.string(),
  login: z.string(),
  password: z.string(),
  mapping: z.object({}),
  GroupMapping: z.object({})
});

const authSchema = z.object({
  useLDAP: z.literal('true'),
  server: z.string().min(1, { message: i18n.t('errors.enterServer') }),
  login: z.string().min(1, { message: i18n.t('servers.addLogin') }),
  password: z.string().min(1, { message: i18n.t('errors.enterPassword') }),
  UseSsl: z.boolean().optional(),
  Port: portSchema,
  DN: z.string().min(1, { message: i18n.t('filials.authorization.dn') }),
  Domain: z.string().min(1, { message: i18n.t('errors.enterDomain') }),
  UserFilter: z.string().min(1, { message: i18n.t('errors.enterFilter') }),

  mapping: z.object({
    principal_name: z.string().min(1, { message: i18n.t('errors.enterGeneralName') }),
    login: z.string().min(1, { message: i18n.t('servers.addLogin') }),
    first_name: z.string().min(1, { message: i18n.t('errors.enterName') }),
    second_name: z.string().min(1, { message: i18n.t('errors.enterLastName') }),
    email: z
      .string()
      // .email({ message: 'Введите валидный адрес почты в формате:  example@mail.com' })
      .or(z.literal(''))
      .optional()
      .nullable(),
    phone: z
      .string()
      // .regex(phoneRegExp, { message: phoneRegExpErrorText })
      .or(z.literal(''))
      .optional()
      .nullable(),
    position: z.string().or(z.literal('')).optional().nullable(),
    organization: z.string().or(z.literal('')).optional().nullable(),
    city: z.string().or(z.literal('')).optional().nullable(),
    isDeleted: z.string().or(z.literal('')).optional().nullable()
  }),
  GroupEnabled: z.literal('false'),
  GroupDn: z.string().optional().nullable(),
  GroupFilter: z.string().optional().nullable(),
  GroupMapping: z.object({})
});

const enabledGroupExportSchema = authSchema.extend({
  useLDAP: z.literal('true'),
  GroupEnabled: z.literal('true'),
  GroupDn: z.string().min(1, { message: i18n.t('errors.enterDn') }),
  GroupFilter: z.string().min(1, { message: i18n.t('errors.enterGroupFilter') }),
  GroupMapping: z.object({
    Description: z.string().optional().nullable(),
    Name: z.string().min(1, { message: i18n.t('errors.enterName') }),
    Uid: z.string().min(1, { message: i18n.t('errors.enterId') }),
    Member: z.string().min(1, { message: i18n.t('errors.enterUserId') })
  })
});

const validateScheme = z.union([noAuthSchema, authSchema, enabledGroupExportSchema]);

const LDAPPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);

  const saveSettings = useSaveSettings();
  const { SyncSettingsLdap, isLdapSync } = ldapSettingsSync();

  const form = useForm<ILDAPForm>({
    initialValues: {
      useLDAP: 'false',
      server: '',
      login: '',
      password: '',
      Port: 0,
      DN: '',
      Domain: '',
      UserFilter: '',
      UseSsl: false,
      mapping: {
        principal_name: '',
        login: '',
        first_name: '',
        second_name: '',
        middle_name: '',
        email: '',
        phone: '',
        birthdate: '',
        position: '',
        organization: '',
        city: '',
        isDeleted: ''
      },
      GroupEnabled: 'false',
      GroupDn: '',
      GroupFilter: '',
      GroupMapping: {
        Description: '',
        Name: '',
        Uid: '',
        Member: ''
      }
    },
    validateInputOnChange: true,
    validate: (values: ILDAPForm): FormErrors => getValidateErrors(validateScheme, values)
  });

  useEffect(() => {
    const { Ldap } = filial?.Settings || {};
    if (!Ldap) {
      return;
    }

    const Mapping = Ldap?.Mapping || {};
    const GroupMapping = Ldap?.GroupMapping || {};

    const formData = {
      useLDAP: `${Ldap.Enabled}`,
      server: Ldap.Server || '',
      login: Ldap.Login || '',
      password: Ldap.Password || '',
      Port: Ldap?.Port || 0,
      Domain: Ldap?.Domain || '',
      DN: Ldap?.DN || '',
      UserFilter: Ldap?.UserFilter || '',
      UseSsl: Ldap?.UseSsl || false,
      mapping: {
        principal_name: Mapping?.PrincipalName || '',
        login: Mapping?.Login || '',
        first_name: Mapping?.FirstName || '',
        second_name: Mapping?.SecondName || '',
        middle_name: Mapping?.MiddleName || '',
        email: Mapping?.Email || '',
        phone: Mapping?.Phone || '',
        birthdate: Mapping?.BirthDate || '',
        position: Mapping?.Position || '',
        organization: Mapping?.Organization || '',
        city: Mapping?.City || '',
        isDeleted: Mapping?.IsDeleted || ''
      },
      GroupEnabled: `${Ldap.GroupEnabled}`,
      GroupDn: Ldap.GroupDn || '',
      GroupFilter: Ldap.GroupFilter || '',
      GroupMapping: {
        Description: GroupMapping?.Description || '',
        Name: GroupMapping.Name || '',
        Uid: GroupMapping.Uid || '',
        Member: GroupMapping.Member || ''
      }
    };

    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleSubmit = (formValues: ILDAPForm) => {
    if (!filial) {
      return;
    }

    const enabled = formValues.useLDAP === 'true';
    const GroupExport = formValues.GroupEnabled === 'true';

    saveSettings(filial.Id, SettingsEnum.Ldap, {
      Ldap: {
        Enabled: enabled,
        Server: enabled ? formValues.server : null,
        Login: enabled ? formValues.login : null,
        Password: enabled ? formValues.password : null,
        Port: enabled ? formValues.Port : null,
        Domain: enabled ? formValues.Domain : null,
        UseSsl: enabled ? formValues.UseSsl : false,
        DN: enabled ? formValues.DN : null,
        UserFilter: enabled ? formValues.UserFilter : null,
        Mapping: {
          PrincipalName: enabled ? formValues.mapping?.principal_name : null,
          Login: enabled ? formValues.mapping?.login : null,
          FirstName: enabled ? formValues.mapping?.first_name : null,
          SecondName: enabled ? formValues.mapping?.second_name : null,
          MiddleName: enabled ? formValues.mapping?.middle_name : null,
          Email: enabled ? formValues.mapping?.email : null,
          Phone: enabled ? formValues.mapping?.phone : null,
          BirthDate: enabled ? formValues.mapping?.birthdate : null,
          Position: enabled ? formValues.mapping?.position : null,
          Organization: enabled ? formValues.mapping?.organization : null,
          City: enabled ? formValues.mapping?.city : null,
          IsDeleted: enabled ? formValues.mapping?.isDeleted : null
        },
        GroupEnabled: enabled && GroupExport ? Boolean(GroupExport) : false,
        GroupDn: enabled && GroupExport ? formValues.GroupDn : null,
        GroupFilter: enabled && GroupExport ? formValues.GroupFilter : null,
        GroupMapping: {
          Uid: enabled && GroupExport ? formValues.GroupMapping?.Uid : null,
          Name: enabled && GroupExport ? formValues.GroupMapping?.Name : null,
          Description: enabled && GroupExport ? formValues.GroupMapping?.Description : null,
          Member: enabled && GroupExport ? formValues.GroupMapping?.Member : null
        }
      }
    });
  };

  const handleRefresh = async () => {
    if (filial) {
      await SyncSettingsLdap(filial?.Id);
    }
  };

  const isMobile = useMediaQuery('(max-width: 576px)');

  return (
    <Box className={classes.container}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex className={classes.section} align="flex-start" direction="column">
          <Text className={classes.title}>{t('filials.authorization.method')}</Text>

          <Radio.Group {...form.getInputProps('useLDAP')}>
            <Radio
              className={classes.radio}
              size="xs"
              label={t('filials.authorization.local')}
              value="false"
            />
            <Radio className={classes.radio} size="xs" label="LDAP" value="true" />
          </Radio.Group>

          {form.getTransformedValues().useLDAP === 'true' && (
            <>
              <Grid columns={isMobile ? 1 : 2} style={{ alignSelf: 'stretch' }} mt="md">
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('servers.server')}
                    withAsterisk
                    {...form.getInputProps('server')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('domains.domain')}
                    withAsterisk
                    {...form.getInputProps('Domain')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('filials.authorization.dn')}
                    withAsterisk
                    {...form.getInputProps('DN')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <NumberInput
                    className={classes.textFieldInput}
                    placeholder={t('servers.port')}
                    label={t('servers.port')}
                    autoComplete="off"
                    withAsterisk
                    hideControls
                    {...form.getInputProps('Port')}
                  />
                </Grid.Col>
                <SimpleGrid mt="md" cols={1} w="100%">
                  <Grid.Col span={1}>
                    <Textarea
                      className={classes.textFieldInput}
                      withAsterisk
                      {...form.getInputProps('UserFilter')}
                      label={t('filials.authorization.filter')}
                    />
                  </Grid.Col>
                </SimpleGrid>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    label={t('profile.login')}
                    withAsterisk
                    {...form.getInputProps('login')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PasswordInput
                    className={classes.textFieldInput}
                    label={t('login.password')}
                    withAsterisk
                    {...form.getInputProps('password')}
                  />
                </Grid.Col>
              </Grid>
              <SimpleGrid mt="md" cols={1}>
                <Checkbox
                  sx={{
                    '& input': {
                      cursor: 'pointer'
                    }
                  }}
                  size="sm"
                  {...form.getInputProps('UseSsl', { type: 'checkbox' })}
                  label={t('filials.authorization.ssl')}
                />
              </SimpleGrid>
              <Text className={classes.title} mb={10} mt={32}>
                {t('filials.authorization.attributes')}
              </Text>
              <Text className={classes.description} mb={16} mt={0}>
                {t('filials.authorization.description')}
              </Text>
              <Grid columns={2} className={classes.groupGrid} mt="md">
                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('filials.authorization.identifier')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.principal_name')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('profile.login')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.login')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('name')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.first_name')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>
                    {t('profile.surName')} <span className={classes.red}>*</span>
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.second_name')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('profile.middleName')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.middle_name')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}> {t('filials.authorization.mail')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.email')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}> {t('profile.phone')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.phone')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.birthdate')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.birthdate')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('profile.position')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.position')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.organization')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.organization')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.address')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.city')}
                  />
                </Grid.Col>

                <Grid.Col span={1} pt={0}>
                  <Text className={classes.label}>{t('filials.authorization.blocked')}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    className={classes.textFieldInput}
                    withAsterisk
                    {...form.getInputProps('mapping.isDeleted')}
                  />
                </Grid.Col>
              </Grid>

              <Divider
                style={{ width: '100%', marginTop: '32px', marginBottom: '16px', color: '#D0D5DD' }}
              />

              <Text className={classes.title}>{t('filials.authorization.exportGroup')}</Text>
              <Text className={classes.description} mb={16} mt={0}>
                {t('filials.authorization.exportModel')}
              </Text>

              <Radio.Group {...form.getInputProps('GroupEnabled')}>
                <Radio className={classes.radio} size="xs" label={t('disable')} value="false" />
                <Radio className={classes.radio} size="xs" label={t('enable')} value="true" />
              </Radio.Group>
              {form.getTransformedValues().GroupEnabled === 'true' && (
                <>
                  <Grid columns={isMobile ? 1 : 2} style={{ alignSelf: 'stretch' }} mt="md">
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        label={t('filials.authorization.dnGroup')}
                        withAsterisk
                        {...form.getInputProps('GroupDn')}
                      />
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        label={t('filials.authorization.filterGroup')}
                        withAsterisk
                        {...form.getInputProps('GroupFilter')}
                      />
                    </Grid.Col>
                  </Grid>
                  <Text className={classes.title} mb={10} mt={32}>
                    {t('filials.authorization.groupAttributes')}
                  </Text>
                  <Text className={classes.description} mb={16} mt={0}>
                    {t('filials.authorization.groupDescription')}
                  </Text>
                  <Grid columns={2} className={classes.groupGrid} mt="md">
                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}>
                        {t('filials.authorization.id')} <span className={classes.red}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Uid')}
                      />
                    </Grid.Col>

                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}>
                        {t('profile.firstName')} <span className={classes.red}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Name')}
                      />
                    </Grid.Col>

                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}>
                        {t('filials.authorization.userId')} <span className={classes.red}>*</span>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Member')}
                      />
                    </Grid.Col>

                    <Grid.Col span={1} pt={0}>
                      <Text className={classes.label}> {t('filials.authorization.details')}</Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <TextInput
                        className={classes.textFieldInput}
                        withAsterisk
                        {...form.getInputProps('GroupMapping.Description')}
                      />
                    </Grid.Col>
                  </Grid>
                </>
              )}
            </>
          )}
          <Flex w="100%">
            <Button
              type="submit"
              size="sm"
              radius={8}
              disabled={!form.isValid() || !form.isDirty()}
              mt="md"
              mr="md"
              style={{ padding: '0 20px' }}
            >
              {t('save')}
            </Button>

            {filial?.Settings?.Ldap.Enabled ? (
              <Button
                size="sm"
                radius={8}
                color="green"
                mt="md"
                rightIcon={<IconRefresh size={24} className={isLdapSync ? classes.rotate : ''} />}
                onClick={() => handleRefresh()}
                disabled={isLdapSync}
              >
                {t('sync')}
              </Button>
            ) : null}
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default LDAPPage;
