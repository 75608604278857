import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';
import { customBaseQuery } from '@/utils/customBaseQuery';
import { ICommonSettings, IUpdateCommonSettings, IUploadedFileResponse } from './types';

export const commonSettingsApi = createApi({
  reducerPath: 'api/commonSettings',
  baseQuery: customBaseQuery(`${BASE_URL}`),
  refetchOnFocus: false,
  tagTypes: ['UserInfo'],
  endpoints: (build) => ({
    commonSettings: build.query<ICommonSettings, void>({
      query: () => ({
        url: 'v1/settings/common'
      })
      // transformResponse: (response: IFullResponse<ICommonSettings>) => response.Response.Data
    }),

    changeCommonSettings: build.mutation<void, IUpdateCommonSettings>({
      query: (payload?: IUpdateCommonSettings) => ({
        url: `v1/settings/common`,
        body: {
          ...payload
        },
        method: 'post'
      })
    }),

    uploadFile: build.mutation<IUploadedFileResponse, FormData>({
      query: (payload: FormData) => ({
        url: `v1/Files/Upload`,
        body: payload,
        method: 'post'
      })
    }),

    syncTeamSettings: build.query<void, void>({
      query: () => ({
        url: 'v2/userprofile/StartExportUser'
      })
    })
  })
});

export const {
  useCommonSettingsQuery,
  useChangeCommonSettingsMutation,
  useLazyCommonSettingsQuery,
  useLazySyncTeamSettingsQuery,
  useUploadFileMutation
} = commonSettingsApi;
