import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  gender: {
    '& .mantine-Select-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    },

    '& .mantine-Select-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px',
      fontWeight: 400
    }
  }
}));
