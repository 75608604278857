import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  selector: {
    '& .mantine-Select-root, & .mantine-Select-input': {
      borderRadius: '20px',
      width: '170px',
      backgroundColor: 'rgba(255, 255, 255)',
      border: '1px solid #D0D5DD',
      [theme.fn.largerThan('xs')]: {
        width: '200px'
      }
    }
  },

  blue: {
    '& .mantine-Select-root, & .mantine-Select-input': {
      color: '#175CD3',
      backgroundColor: 'rgba(23, 92, 211, 0.2)'
    }
  },
  red: {
    '& .mantine-Select-root, & .mantine-Select-input': {
      color: theme.colors.brandRed[0],
      backgroundColor: 'rgba(194, 57, 57, 0.2)'
    }
  }
}));
