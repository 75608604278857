import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  track: {
    left: -2,
    height: 10,
    border: 'none',
    overflow: 'inherit',
    cursor: 'pointer',
    minWidth: 28,

    'input:checked + &': {
      backgroundColor: '#53B1FD'
    }
  },
  thumb: {
    left: -2,
    width: 16,
    height: 16,
    borderRadius: '50%',

    'input:checked + * > &': {
      border: 'none'
    }
  },

  body: {
    alignItems: 'center'
  },
  label: {
    fontSize: 14
  },
  trackLabel: {
    minWidth: 15
  }
}));
