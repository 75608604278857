import { z } from 'zod';
import i18n from '@/entities/i18next';

const isValidIPv4 = (value: string) => {
  return z.string().ip({ version: 'v4' }).safeParse(value).success;
};

export const validateSchemeIpSecurity = z.object({
  addresses: z.array(
    z.object({
      address: z
        .string()
        .refine(
          (value) => {
            if (value.includes('-')) {
              const indexOfSeparator = value.indexOf('-');
              const startIp = value.substring(0, indexOfSeparator);
              const endIp = value.substring(indexOfSeparator + 1);
              return isValidIPv4(startIp) && isValidIPv4(endIp);
            }
            return true;
          },
          { message: i18n.t('validation.ipAddressRange') }
        )
        .refine(
          (value) => {
            if (value.includes('-')) {
              return true;
            }
            return isValidIPv4(value);
          },
          { message: i18n.t('validation.ipAddress') }
        )
    })
  )
});
