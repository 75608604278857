import { useState, useEffect } from 'react';

import { SearchIcon } from '@assets/icons';
import { Search } from '@components/search/search-input';

import { useDebounce } from '@hooks/useDebounce';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';

import { useStyles } from '../../styles';
import { useTranslation } from 'react-i18next';

export const RolesSearch = () => {
  const { classes } = useStyles();

  const { setRolesTableFilters } = useActions();
  const { filters: rolesFilters } = useAppSelector((state) => state.roles);

  const [text, setText] = useState(rolesFilters.Query || '');

  const debounced = useDebounce(text, 500);

  const { t } = useTranslation();

  useEffect(() => {
    setRolesTableFilters({
      ...rolesFilters,
      Query: debounced,
      Offset: 0
    });
  }, [debounced]);

  return (
    <Search
      icon={<SearchIcon />}
      setSearch={setText}
      className={classes.search}
      fullSize
      value={text}
      placeholder={t('searchRolesByName')}
    />
  );
};
