import { FC, useContext, useRef, useState } from 'react';
import { Box, Button, Text, UnstyledButton } from '@mantine/core';
import { Modal } from '@components/modal';
import { useStyles } from './styles';
import { AvatarEditor } from '@components/avatar-editor';
import { base64ToBlob } from '@/lib/files';
import { uploadFile } from '@/api/files';
import { CreateUserContext } from '../../index';
import { useTranslation } from 'react-i18next';

interface IAvatarEditModalProps {
  preview: string;
  setPreview: (value: string) => void;
  isOpened: boolean;
  title: string;
  onClose: () => void;
}

export const AvatarEditModal: FC<IAvatarEditModalProps> = ({
  isOpened,
  title,
  onClose,
  setPreview
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { form } = useContext(CreateUserContext);
  const AvatarIdFormKey = 'AvatarId';
  const [avatar, setAvatar] = useState<string>('');
  const openRef = useRef<() => void>(null);
  const avatarRef = useRef<any>(null);

  const saveAvatar = async () => {
    const img = avatarRef?.current?.getImageScaledToCanvas().toDataURL();
    if (img) {
      setPreview(img);
      const file = await base64ToBlob(avatar);
      const formData = new FormData();
      formData.append('File', file);

      try {
        const res = await uploadFile(formData);

        form.setFieldValue(AvatarIdFormKey, res.data.Id);

        if (res.status === 200) {
          onClose();
        }
      } catch (e) {
        console.log('uploadFile ERROR', e);
      }
      setAvatar('');
    }
  };

  const clickInAvatar = async () => {
    if (openRef.current) {
      openRef.current();
    }
  };

  const close = () => {
    onClose();
    setAvatar('');
  };

  return (
    <Modal opened={isOpened} title={title} onClose={close}>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <AvatarEditor
            openRef={openRef}
            avatarRef={avatarRef}
            avatar={avatar}
            setAvatar={setAvatar}
          />
          <Box className={classes.control}>
            <Text size="sm">{t('avatar.conditions')}</Text>
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Button size="sm" radius={8} w="49%" onClick={saveAvatar} disabled={!avatar}>
            {t('save')}
          </Button>
          {avatar ? (
            <UnstyledButton ml={8} w="49%" onClick={clickInAvatar} className={classes.cancelBtn}>
              {t('avatar.changePhoto')}
            </UnstyledButton>
          ) : (
            <UnstyledButton ml={8} w="49%" onClick={close} className={classes.cancelBtn}>
              {t('cancel')}
            </UnstyledButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
