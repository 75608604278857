import { IUserTableData } from '@/entities/admin-app/users';
import { Box, Text, Avatar, Flex } from '@mantine/core';
import { FC, SyntheticEvent } from 'react';
import { useStyles } from './styles';
import { themeColors } from '@/theme/colors';
import { RoleTypesEnum } from '@/types/enums/user';
import { IconTrash } from '@tabler/icons';

interface IUserItemProps {
  item: IUserTableData;
  userId?: number;
  handleClick: (event: SyntheticEvent<HTMLDivElement>) => void;
  roleType: number | undefined;
}

export const UserItem: FC<IUserItemProps> = ({ item, handleClick, roleType }) => {
  const { classes } = useStyles();

  return (
    <Flex className={classes.container}>
      <Box className={classes.content}>
        <Avatar size={40} src={item.Avatar} radius={62} />
        <Box className={classes.info}>
          <Text size="sm" weight={500}>
            {item.Surname} {item.FirstName}
          </Text>
          <Text color="dimmed" size="xs">
            {item.Email}
          </Text>
        </Box>
      </Box>
      {roleType !== RoleTypesEnum.All && (
        <Box className="deleteIcon" onClick={handleClick}>
          <IconTrash
            size="1.5rem"
            color={themeColors.brandGray[0]}
            stroke={1.5}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      )}
    </Flex>
  );
};
