import { forwardRef, PropsWithChildren, ReactNode, useContext } from 'react';
import { Loader, Box } from '@mantine/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStyles } from '../styles';
import { SelectContext } from '../index';

interface DropDownComponentProps extends PropsWithChildren {
  children: ReactNode;
}

export const DropDownComponent = forwardRef<HTMLDivElement, DropDownComponentProps>(
  ({ children, ...props }, ref) => {
    const { classes } = useStyles();
    const { loadMore, list, total } = useContext(SelectContext);

    return (
      <Box sx={{ width: '100%' }} {...props} ref={ref}>
        <div id="scrollableDiv" style={{ overflow: 'auto' }}>
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            style={{ height: 'auto', maxHeight: 150, minHeight: 50 }}
            height={150}
            dataLength={list?.length}
            next={loadMore}
            hasMore={total > list.length}
            loader={
              <Box className={classes.infiniteScrollMoreData}>
                <Loader size="sm" />
              </Box>
            }
            className={classes.infiniteScroll}
          >
            {children}
          </InfiniteScroll>
        </div>
      </Box>
    );
  }
);

DropDownComponent.displayName = 'DropDownComponent';
