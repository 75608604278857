import { FC, ElementType } from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import { Box, NavLink, Text } from '@mantine/core';

import { menuProfilePage } from '@/mock-data/navbar-menu';

import { useStyles } from './styles';
import { ROUTES } from '@/containers/routes/constants';

const linksBlocks = [{ label: 'Профиль', pages: menuProfilePage }];

const { fullPath, pages } = ROUTES.profile;
const routes = Object.values(pages).map((value) => {
  const path = `${fullPath}/${value ? `${value}` : ''}`;
  return { path, chunk: value };
});

interface IMenuProps {
  setIsOpen: (isOpen: boolean) => void;
}

export const NavMenu: FC<IMenuProps> = ({ setIsOpen }) => {
  const { classes } = useStyles();
  const location = useLocation();
  const [currentRoute] = matchRoutes(routes, location) || [];
  const currentPage = currentRoute?.route?.chunk;

  const handleClickClose = () => {
    return setIsOpen(false);
  };

  return (
    <>
      {linksBlocks.map((block, index) => (
        <Box key={index} mb="sm">
          <Text className={classes.heading} fz="sm" ml="sm">
            {block.label}
          </Text>
          {block.pages.map((item, index) => {
            const isActive = item.value === currentPage;
            const Icon = item.icon as ElementType;
            return (
              <NavLink
                className={classes.navLinkItem}
                key={index}
                label={item.label}
                icon={<Icon stroke={isActive ? '#101828' : '#667085'} />}
                component={Link}
                onClick={() => handleClickClose()}
                to={item.value}
                active={isActive}
              />
            );
          })}
        </Box>
      ))}
    </>
  );
};
