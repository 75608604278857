import { Box, Button, Image, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface IAccessProps {
  iconSrc?: string;
  moduleTitle?: string;
  handleClose: () => void;
}
const NoAccessModuleCard = ({ iconSrc, moduleTitle, handleClose }: IAccessProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Box className={classes.logoContainer}>
        <Image src={iconSrc} width={32} alt={moduleTitle} withPlaceholder />
        <Text fz="xl" fw="bold" ml="sm">
          {moduleTitle?.toUpperCase()}
        </Text>
      </Box>
      <Box>
        <Text className={classes.descriptionTitle}>{t('errors.accessError')}</Text>
        <Text className={classes.descriptionText}>
          {t('errors.doNotAccessToModule', { module: moduleTitle })}
        </Text>
      </Box>
      <Box w="100%">
        <Button className={classes.closeButton} size="lg" fullWidth={true} onClick={handleClose}>
          <Text>{t('close')}</Text>
        </Button>
      </Box>
    </Box>
  );
};

export default NoAccessModuleCard;
