import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  link: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}`,
    marginBottom: '4px',
    backgroundColor: theme.fn.rgba(theme.colors.gray[2], 0.2),
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    borderRadius: '8px',
    width: '100%',
    cursor: 'pointer',

    '& input, label': {
      cursor: 'pointer'
    },

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black
    },

    '& .mantine-Checkbox-root, .mantine-Checkbox-body, .mantine-Checkbox-labelWrapper': {
      width: '100%'
    },
    '& .mantine-Checkbox-label': {
      paddingLeft: theme.spacing.xs
    }
  },

  counter: {
    fontSize: '12px',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    marginLeft: '12px'
  },

  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },

  checkbox: {
    cursor: 'pointer',
    fontWeight: 400,
    width: '220px',
    paddingRight: theme.spacing.xs,
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  }
}));
