import { useRef, useState, useCallback, SyntheticEvent, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Loader, Text } from '@mantine/core';

import { ListItem } from '../../list/list-item';
import { Search } from '../../search/search-input';
import { FilterTemplate } from '../filter-template';

import { IFilterItem, IFilterMenuItem } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';
import { IFilterItemsRequest, IFilterItemsResponse } from '@/entities/admin-app/users';

import { useDebounce } from '@hooks/useDebounce';

import { useStyles } from './styles';
import { NoDataText } from '@/components/not-found/components/no-data-text';

interface ILoadMoreProps {
  Type: IFiltersEnumType;
  Count: number;
  Offset: number;
  Query: string;
}

interface ISearchFilterProps {
  defaultListIds?: number[];
  handleFilterChange?: (data: { Type: number; value: number }) => void;
  filterType: IFiltersEnumType;
  list: IFilterMenuItem[] | null;
  activeIds: number[];
  label: string;
  searchPlaceholder?: string;
  hideSearch?: boolean;
  hideCollapse?: boolean;
  handleFilterReset: (value: number) => void;
  handleLoadData: (
    args: ILoadMoreProps,
    opts: { skip: boolean }
  ) => { data?: IFilterItemsResponse; isLoading: boolean };
}
export const SearchFilter = ({
  filterType = IFiltersEnumType.RoleFilter,
  list,
  activeIds,
  label,
  searchPlaceholder = 'Поиск...',
  hideSearch,
  hideCollapse,
  handleFilterReset,
  handleFilterChange,
  handleLoadData
}: ISearchFilterProps) => {
  const { classes } = useStyles();
  const [skip, setSkip] = useState<boolean>(!list?.length || list.length < 9);
  const scrollRef = useRef<any>(null);
  const listIdsRef = useRef<number[]>(activeIds || []);

  const [filters, setFilters] = useState<IFilterItemsRequest>({
    Type: filterType,
    Count: 10,
    Offset: 0,
    Query: ''
  });
  const [search, setSearch] = useState<string>('');
  const debounced = useDebounce(search, 500);

  const { data, isLoading } = handleLoadData(
    {
      Type: filters.Type,
      Count: 10,
      Offset: filters.Offset,
      Query: debounced
    },
    {
      skip: skip
    }
  );

  const [listData, setListData] = useState<IFilterMenuItem[]>(list || []);

  const handleClickLinkItem = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      const value = Number(event?.currentTarget?.dataset?.link);

      if (handleFilterChange) {
        handleFilterChange({ Type: filterType, value: value });
      }

      const ids = listIdsRef.current.includes(value)
        ? listIdsRef.current.filter((id) => value !== id)
        : new Set([...listIdsRef.current, value]);

      listIdsRef.current = [...ids];

      // setFilters((prevState: any) => ({ ...prevState, [currentValueType]: listIdsRef.current }));
    },
    [activeIds]
  );

  // const openDropdownMenu = () => {
  //   setOpened((prevState) => !prevState);
  // };

  // const selectAll = () => {
  //   setSelectionAll(true);
  //   setListData((prevState) => prevState.map((item) => ({ ...item, checked: true })));
  //   listIdsRef.current = listData?.map((item) => Number(item.value));
  // };

  // const resetAll = () => {
  //   setSelectionAll(false);
  //   setListData((prevState) => prevState.map((item) => ({ ...item, checked: false })));
  //   listIdsRef.current = [];
  // };

  const handleLoadMore = () => {
    setSkip(false);
    setFilters((prevState: any) => ({
      ...prevState,
      Offset: prevState.Offset + 10
    }));
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const formattedData: IFilterMenuItem[] = data?.FilterItems?.map((item: IFilterItem) => ({
      ...item,
      checked: false
    }));
    const filterData = filters?.Offset > 0 ? [...listData, ...formattedData] : formattedData;
    const convertData = filterData?.map((item: IFilterMenuItem) => ({
      ...item,
      checked: activeIds?.length ? listIdsRef?.current?.includes(Number(item.Id)) : false
    }));
    setListData(convertData);
  }, [data]);

  useEffect(() => {
    if (search) {
      scrollRef?.current?.el?.scrollTo(0, 0);
      setSkip(false);
    }

    setFilters((prevState: any) => ({
      ...prevState,
      Query: search,
      Offset: 0
    }));
  }, [search]);

  const items = listData?.map((item) => (
    <ListItem
      key={`${item.Id}${item?.Name}`}
      label={item?.Name}
      handleChange={handleClickLinkItem}
      value={Number(item?.Id)}
      counter={item?.Count}
      checked={activeIds?.includes(item?.Id)}
    />
  ));

  return (
    <FilterTemplate
      handleFilterReset={handleFilterReset}
      activeIds={activeIds?.length}
      label={label}
      hideCollapse={hideCollapse}
      type={filterType}
    >
      <Box className={classes.root}>
        <>
          {hideSearch || (
            <Search
              setSearch={setSearch}
              className={classes.search}
              fullSize
              value={search}
              placeholder={searchPlaceholder}
            />
          )}
          {listData?.length ? (
            <>
              {/*{selectionAll ? (*/}
              {/*  <Text className={classes.selectAll} onClick={resetAll}>*/}
              {/*    Сбросить*/}
              {/*  </Text>*/}
              {/*) : (*/}
              {/*  <Text className={classes.selectAll} onClick={selectAll}>*/}
              {/*    Выбрать всех*/}
              {/*  </Text>*/}
              {/*)}*/}

              <InfiniteScroll
                height={listData?.length < 5 ? 'auto' : '200px'}
                className={classes.infiniteScroll}
                dataLength={listData?.length}
                next={handleLoadMore}
                hasMore={data ? data?.TotalCount > listData?.length : false}
                loader={
                  <>
                    {Boolean(listData?.length) && (
                      <Box className={classes.infiniteScrollMoreData}>
                        <Loader color="mainBlue.9" size="sm" />
                      </Box>
                    )}
                  </>
                }
              >
                {items}
              </InfiniteScroll>
            </>
          ) : (
            <Text size={14} w="224px">
              <NoDataText />
            </Text>
          )}
          {isLoading ? (
            <Box className={classes.loader}>
              <Loader color="mainBlue.9" size="sm" />
            </Box>
          ) : null}
        </>
      </Box>
    </FilterTemplate>
  );
};
