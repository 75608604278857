import { useEffect, useState } from 'react';
import { useGetDefaultUserFiltersQuery } from '@/entities/admin-app/users/api';
import { IFilterMenu } from '@/types/api';
import { filtersLabels } from '@/entities/admin-app/constants';

interface FiltersSettingsInterface {
  [index: number]: {
    hideSearch: boolean;
  };
}

export const filtersSettings: FiltersSettingsInterface = {
  1: {
    hideSearch: false
  },
  2: {
    hideSearch: true
  },
  3: {
    hideSearch: false
  },
  4: {
    hideSearch: false
  },
  5: {
    hideSearch: false
  },
  6: {
    hideSearch: true
  },
  7: {
    hideSearch: true
  },
  8: {
    hideSearch: true
  },
  9: {
    hideSearch: true
  },
  10: {
    hideSearch: false
  },
  11: {
    hideSearch: false
  },
  12: {
    hideSearch: false
  },
  13: {
    hideSearch: false
  },
  14: {
    hideSearch: false
  },
  15: {
    hideSearch: false
  },
  16: {
    hideSearch: false
  },
  17: {
    hideSearch: false
  }
};

export const useGetUsersFilters = () => {
  const { data: defaultFiltersData, isSuccess: defaultFiltersSuccess } =
    useGetDefaultUserFiltersQuery(20);
  const [filtersMenuList, setFiltersMenuList] = useState<IFilterMenu[]>([]);

  useEffect(() => {
    if (defaultFiltersData && defaultFiltersSuccess) {
      const convertDefaultFilters: IFilterMenu[] = defaultFiltersData
        ?.map((item) => ({
          ...item,
          label: filtersLabels[item.Type] || 'Заголовок',
          Items: item.Items?.map((filterItem) => ({ ...filterItem, checked: false })),
          hideSearch: filtersSettings[item.Type]?.hideSearch ?? false
        }))
        .sort((a, b) => a.Type - b.Type)
        .filter((item) => item.Type !== 10);
      setFiltersMenuList(convertDefaultFilters);
    }
  }, [defaultFiltersData, defaultFiltersSuccess]);

  return { filtersMenuList };
};
