import { FC, ElementType } from 'react';
import { Link, useLocation, matchRoutes } from 'react-router-dom';
import { Box, Text, NavLink } from '@mantine/core';
import { menuCommonPages } from '../../utiils';
import { ROUTES } from '@/containers/routes/constants';
import { useStyles } from './styles';
import { modulesMenuPages } from '@/mock-data/navbar-menu';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';

interface IMenuProps {
  setIsOpen: (isOpen: boolean) => void;
}

const { fullPath, pages } = ROUTES.filials.edit;
const routes = Object.values(pages)
  .map((page) => {
    return Object.values(page).map((route) => ({
      path: `${fullPath}/${route}`,
      chunk: route
    }));
  })
  .flat();

export const NavMenu: FC<IMenuProps> = ({ setIsOpen }) => {
  const { classes } = useStyles();
  const location = useLocation();
  const [currentRoute] = matchRoutes(routes, location) || [];
  const { Settings } = useAppSelector((state) => state.userInfo);
  const currentPage = currentRoute?.route?.chunk;

  const handleClickClose = () => {
    return setIsOpen(false);
  };

  const linksBlocks = [
    {
      label: 'Общие',
      pages: menuCommonPages.filter(
        (item) => !isSaaS(Settings) || !['mailing-setup'].includes(item.value)
      )
    },
    {
      label: 'Модули',
      pages: isSaaS(Settings)
        ? modulesMenuPages
        : modulesMenuPages.filter(
            (module) => module.value !== ROUTES.filials.edit.pages.modules.projects
          )
    }
  ];

  return (
    <>
      {linksBlocks.map((block, index) => (
        <Box key={index} mb="sm">
          <Text className={classes.heading} fz="sm" ml="sm">
            {block.label}
          </Text>
          {block.pages.map((item, index) => {
            const isActive = item.value === currentPage;
            const Icon = item.icon as ElementType;
            return (
              <NavLink
                className={classes.navLinkItem}
                key={index}
                label={item.label}
                icon={<Icon stroke={isActive ? '#101828' : '#667085'} />}
                component={Link}
                onClick={() => handleClickClose()}
                to={item.value}
                active={isActive}
              />
            );
          })}
        </Box>
      ))}
    </>
  );
};
