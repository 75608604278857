import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  list: {
    position: 'relative',
    margin: 0,
    padding: 0,
    overflow: 'auto',
    listStyleType: 'none',
    maxHeight: 320
  },
  header: {
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${theme.colors.backgroundGray[6]}`,
    backgroundColor: '#FFFFFF',
    zIndex: 2
  },

  progress: {
    marginBottom: theme.spacing.sm,
    marginTop: theme.spacing.sm,
    width: 300
  },
  transparent: {
    opacity: 0.05,
    pointerEvents: 'none'
  },
  box: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80%'
  }
}));
