import { useState } from 'react';
import { IColumnData, IRowData } from '@components/simple-table/types';
import moment from 'moment';

import { formatDate } from '@/lib/utils/date';

import { ItemTitle as ItemTitle } from '../components/table/item-title';
import { Action } from '../components/table/action';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import i18n from '@/entities/i18next';
import { Box } from '@mantine/core';
import OverflowTip from '@components/OverflowTip';

export const useGetColumns = () => {
  const { Settings } = useAppSelector((state) => state.userInfo);
  const columnsData: IColumnData[] = [
    {
      width: '300px',
      dataIndex: 'Title',
      title: i18n.t('servers.name'),
      hideColumn: false,
      render: (item: IRowData) => <ItemTitle item={item} />
    },
    {
      width: '120px',
      dataIndex: 'Shortname',
      title: i18n.t('filials.settings.filialCode'),
      hideColumn: false,
      render: (item: IRowData) => (
        <Box maw="200px">
          <OverflowTip size="sm">{item?.Company}</OverflowTip>
        </Box>
      )
    },
    {
      width: '246px',
      dataIndex: 'Company',
      title: i18n.t('filials.settings.filialParent'),
      hideColumn: false,
      render: (item: IRowData) => (
        <Box maw="200px">
          <OverflowTip size="sm">{item?.ParentName}</OverflowTip>
        </Box>
      )
    },
    {
      width: '146px',
      dataIndex: 'UsersCount',
      title: i18n.t('filials.diskSpace.table.users'),
      hideColumn: false,
      render: (item: IRowData) => item?.UsersCount
    },
    {
      width: '146px',
      dataIndex: i18n.t('profile.roles'),
      title: 'Роли',
      hideColumn: false,
      render: (item: IRowData) => item?.RolesCount
    },
    {
      width: '146px',
      dataIndex: 'DateOfCreate',
      title: i18n.t('dateOfCreation'),
      hideColumn: false,
      render: (item: IRowData) => formatDate(moment.utc(item?.DateOfCreate))
    },
    {
      width: '50px',
      dataIndex: 'Action',
      title: '',
      hideColumn: false,
      render: (item: IRowData) => <Action item={item} />
    }
  ].filter((item) => !isSaaS(Settings) || !['Shortname', 'Company'].includes(item.dataIndex));

  const [columns, setColumns] = useState<IColumnData[]>(columnsData);

  return { columns, setColumns };
};
