import { ChangeEvent, FC, ReactNode } from 'react';
import { DefaultProps, Input, InputProps, Selectors } from '@mantine/core';
import { ISearchStylesParams, useStyles } from './styles';
import { IconX } from '@tabler/icons';

type MyComponentStylesNames = Selectors<typeof useStyles>;

export interface ISearchProps extends DefaultProps<MyComponentStylesNames, ISearchStylesParams> {
  placeholder?: string;
  props?: InputProps;
  fullSize?: boolean;
  setSearch: (value: string) => void;
  value: string;
  hideClearIcon?: boolean;
  icon?: ReactNode;
}

export const Search: FC<ISearchProps> = ({
  placeholder = 'Search...',
  classNames,
  className,
  fullSize,
  styles,
  unstyled,
  setSearch,
  hideClearIcon,
  icon,
  value,
  ...props
}) => {
  const { classes, cx } = useStyles({ fullSize }, { name: 'Search', classNames, styles, unstyled });

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const clearValue = () => {
    setSearch('');
  };

  return (
    <Input
      data-lpignore="true"
      autoComplete="new-password"
      type="search"
      className={cx(classes.search, className)}
      icon={icon}
      placeholder={placeholder}
      onChange={onSearchChange}
      value={value}
      rightSection={
        !hideClearIcon && value && <IconX className={classes.clearIcon} onClick={clearValue} />
      }
      {...props}
    />
  );
};
