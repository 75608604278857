import { useContext, useEffect, useRef, useState } from 'react';
import { ActionIcon, Badge, Box, Divider, Flex, Loader, Text } from '@mantine/core';
import { useStyles } from './styles';
import { PlusIcon, UsersIcon } from '@/assets/icons';
import { RolesInfoMainContext } from '../../panels';
import { useGetUsersQuery } from '@/entities/admin-app/users/api';
import { IUserTableData } from '@/entities/admin-app/users';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from '@/hooks/useDebounce';
import { themeColors } from '@/theme/colors';
import { UserItem } from './user-item';
import { Search } from '@/components/search/search-input';
import { SelectUserModal } from '@/components/modal/select-user';
import { IFiltersEnumType } from '@/types/enums';
import { useAddRoles } from '@/hooks/users/useAddRoles';
import { useDeleteRole } from '@/hooks/users/useDeleteRoles';
import { RoleTypesEnum } from '@/types/enums/user';
import { useTranslation } from 'react-i18next';

export const UserRoles = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const scrollRef = useRef<any>(null);
  const { rolesInfoCommon } = useContext(RolesInfoMainContext);

  const [search, setSearch] = useState<string>('');
  const [usersList, setUsersList] = useState<IUserTableData[]>([]);
  const [offset, setOffset] = useState(0);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  const { openDeleteRole } = useDeleteRole(setUsersList);
  const { handleAddRoles } = useAddRoles(rolesInfoCommon?.Id ? [rolesInfoCommon.Id] : []);

  const debounced = useDebounce(search, 500);

  const { data, isLoading, isFetching } = useGetUsersQuery({
    Count: 20,
    Offset: offset,
    SortOrder: 0,
    SortField: 0,
    Filters: {},
    Query: debounced,
    FilterConditions: rolesInfoCommon
      ? [
          {
            Type: IFiltersEnumType.RoleFilter,
            NumValues: [rolesInfoCommon?.Id]
          }
        ]
      : []
  });

  useEffect(() => {
    if (data) {
      const usersData = offset > 0 ? [...usersList, ...data.Users] : data.Users;
      setUsersList(usersData);
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      scrollRef?.current?.el?.scrollTo(0, 0);
    }
    setOffset(0);
  }, [search]);

  const handleLoadMore = () => {
    setOffset((prevState) => prevState + 20);
  };

  const closeModal = () => {
    setIsUserModalOpen(false);
  };

  const addRoles = () => {
    if (rolesInfoCommon) {
      const rolesIds = [rolesInfoCommon?.Id];
      handleAddRoles(selectedUsers, rolesIds);
      closeModal();
    }
  };

  const handleDelete = (userId: number) => {
    if (rolesInfoCommon?.Id) {
      openDeleteRole(userId, rolesInfoCommon?.Id);
    }
  };

  return (
    <>
      <Divider className={classes.divider} />
      <Box className={classes.header}>
        <Flex gap="xs" align="center">
          <UsersIcon stroke={themeColors.brandGray[0]} />
          <Text className={classes.title}>Пользователи: {data?.TotalCount}</Text>
        </Flex>
        {rolesInfoCommon?.Type !== RoleTypesEnum.All && (
          <ActionIcon size="md" onClick={() => setIsUserModalOpen(true)}>
            <PlusIcon />
          </ActionIcon>
        )}
      </Box>
      <Search
        setSearch={setSearch}
        className={classes.search}
        fullSize
        value={search}
        placeholder={t('search')}
      />
      <InfiniteScroll
        ref={scrollRef}
        dataLength={usersList?.length}
        next={handleLoadMore}
        hasMore={
          data && data.TotalCount !== undefined ? data.TotalCount > usersList?.length : false
        }
        loader={
          <>
            {Boolean(usersList?.length) && (
              <Box className={classes.infiniteScrollMoreData}>
                <Loader size="sm" />
              </Box>
            )}
          </>
        }
        className={classes.infiniteScroll}
        height={usersList?.length < 5 ? 'auto' : '380px'}
      >
        {(isLoading || isFetching) && (
          <Box className={classes.loader}>
            <Loader size="sm" />
          </Box>
        )}
        {usersList?.length > 0 &&
          !isLoading &&
          usersList?.map((item) => {
            return (
              <UserItem
                key={item.Id}
                item={item}
                userId={item.Id}
                handleClick={() => handleDelete(item.Id)}
                roleType={rolesInfoCommon?.Type}
              />
            );
          })}
        {!data?.Users.length &&
          !search &&
          !isLoading &&
          rolesInfoCommon?.Type !== RoleTypesEnum.All && (
            <Badge
              className={classes.badge}
              variant="outline"
              color="gray"
              onClick={() => setIsUserModalOpen(true)}
              rightSection={
                <ActionIcon size={16} variant="transparent">
                  <PlusIcon />
                </ActionIcon>
              }
            >
              {t('roles.addUsers')}
            </Badge>
          )}
        {!usersList.length && !isLoading && !isFetching && search && (
          <Text className={classes.emptyListText}>{t('userNotFound')}</Text>
        )}
      </InfiniteScroll>
      <SelectUserModal
        multipleUsers={true}
        isOpened={isUserModalOpen}
        title={t('filials.modules.addUser')}
        onClose={closeModal}
        userId={selectedUsers[0]}
        setSelectedUsers={setSelectedUsers}
        usersList={usersList}
        controlSection
        controlSectionProps={{
          confirmProps: {
            btnName: `${t('add')} ${selectedUsers.length > 0 ? selectedUsers.length : ''}`,
            fn: addRoles
          },
          cancelProps: {
            btnName: t('cancel'),
            fn: closeModal
          }
        }}
      />
    </>
  );
};
