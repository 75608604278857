import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  scrolled: {
    boxShadow: theme.shadows.sm
  },

  infiniteScroll: {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#98A2B3'
    },

    '&::-webkit-scrollbar-track': {
      background: '#F9FAFB',
      borderRadius: '8px'
    },

    scrollbarColor: '#98A2B3 #F9FAFB'
  },

  infiniteScrollMoreData: {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  error: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0],
    '& p': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px 10px'
    }
  },
  badge: {
    backgroundColor: theme.colors.backgroundGray[6],
    color: '#344054',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    marginRight: theme.spacing.xs,
    borderRadius: '16px',
    cursor: 'pointer',
    padding: `0px ${theme.spacing.xs}`,

    '& .mantine-Badge-rightSection': {
      display: 'flex'
    }
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none'
  },

  label: {
    cursor: 'pointer',
    width: '220px',
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  }
}));
