import { IUserTypeEnum } from '@/entities/admin-app/user';
import { UserStatusEnum } from '@/types/enums/user';
import i18n from '@/entities/i18next';

export const userTypeOptions = [
  { value: IUserTypeEnum.Contact.toString(), label: 'Локальный' },
  { value: IUserTypeEnum.LDAP.toString(), label: 'LDAP' },
  { value: IUserTypeEnum.SSO.toString(), label: 'SSO' }
];

export const userStatusesOptions = [
  {
    label: i18n.t('users.actionList.active'),
    value: UserStatusEnum.Active.toString()
  },
  {
    label: i18n.t('users.actionList.locked'),
    value: UserStatusEnum.Ban.toString()
  }
];
