import { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Loader, Table, Text } from '@mantine/core';

import { useStyles } from './styles';
import { Th } from './th';
import { IColumnData, IRowData, ISimpleTableProps } from './types';
import { useTranslation } from 'react-i18next';
import { Tr } from './tr';
import { ExpandedTR } from '../expanded-row';

export const SimpleTable: FC<ISimpleTableProps> = ({
  dataCy = 'simpleTable',
  rowData,
  // setRowData,
  columns,
  search,
  minWidth,
  height,
  width,
  selection,
  handleRowClick,
  getNextData,
  allDataLength,
  error,
  scrollRef,
  isLoading,
  isFetching,
  sort,
  setSort,
  activeFilters,
  isExpanded
}) => {
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const { t } = useTranslation();
  const setSorting = (field: keyof IRowData) => {
    if (setSort) {
      setSort({
        SortOrder: field === sort?.SortField ? !sort.SortOrder : false,
        SortField: field
      });
    }
  };

  const filters = activeFilters ? Object.keys(activeFilters).length : 0;

  const rows = rowData?.map((row: IRowData, index: number) => {
    return isExpanded ? (
      <ExpandedTR
        key={index}
        columns={columns}
        row={row}
        selection={selection}
        handleRowClick={handleRowClick}
      />
    ) : (
      <Tr
        key={index}
        columns={columns}
        row={row}
        selection={selection}
        handleRowClick={handleRowClick}
      />
    );
  });

  const columnsRender = columns.map((col: IColumnData) => (
    <Th
      key={col.dataIndex}
      width={col.width}
      sorted={sort?.SortField === col.dataIndex}
      reversed={!!sort?.SortOrder}
      onSort={() => col.hasSort && col.title && setSorting(col.dataIndex)}
      hideSort={!col.hasSort}
      hideColumn={col.hideColumn}
    >
      {col.title}
    </Th>
  ));

  const handleScroll = () => {
    setScrolled(true);
  };

  return (
    <Box sx={{ width }}>
      <InfiniteScroll
        ref={scrollRef}
        dataLength={rowData?.length}
        next={getNextData}
        hasMore={allDataLength > rowData.length}
        loader={
          <>
            {Boolean(rowData.length) && (
              <Box className={classes.infiniteScrollMoreData}>
                <Loader size="sm" />
              </Box>
            )}
          </>
        }
        className={classes.infiniteScroll}
        height={height}
        onScroll={handleScroll}
      >
        <Table horizontalSpacing="md" data-atribute={dataCy} verticalSpacing="xs" sx={{ minWidth }}>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>{columnsRender}</tr>
          </thead>
          <tbody>
            {(isLoading || isFetching) && (
              <tr className={classes.tr} style={{ height }}>
                <td colSpan={columns.length}>
                  <Box className={classes.infiniteScrollMoreData}>
                    <Loader size="sm" />
                  </Box>
                </td>
              </tr>
            )}

            {rowData?.length === 0 && (search || filters > 0) && (
              <tr className={classes.tr} style={{ height }}>
                <td colSpan={columns.length}>
                  {error ? (
                    <Box className={classes.error}>
                      {Object.entries(error).map(([key, value]) => (
                        <p key={key}>
                          {key}: <p>{value}</p>
                        </p>
                      ))}
                    </Box>
                  ) : (
                    <Text weight={500} align="center" color="gray.6">
                      {t('noData')}
                    </Text>
                  )}
                </td>
              </tr>
            )}
            {rowData?.length > 0 && !isLoading && rows}
          </tbody>
        </Table>
      </InfiniteScroll>
    </Box>
  );
};
