import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing.sm,
    backgroundColor: theme.white,
    borderRadius: theme.spacing.xs,
    padding: theme.spacing.md,
    position: 'relative'
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },

  title: {
    fontSize: '16px',
    marginBottom: theme.spacing.md,
    fontWeight: 600
  }
}));
