import { FormErrors as DefaultFormErrors } from '@mantine/form';
import { ZodType } from 'zod';

export const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const emailRegExpErrorText = `Неверный адрес электронной почты. Должен быть в формате "example@email.com"`;

export const passwordRegExp =
  /^(?=.[0-9])(?=.[a-z])(?=.[A-Z])(?=.[$&+,:;=?@#|'<>.^()%!-])[a-zA-Z0-9$&+,:;=?@#|'<>.^()%!-]{8,}$/;
export const passwordRegExpErrorText =
  'Пароль должен содержать не менее 8 символов, одну заглавную букву, одну строчную букву, одну цифру и один специальный символ.';

export const rusRegExp = /^[А-Яа-яЁё`'.\-s]+$/;
export const rusRegExpErrorText = `Используйте только русские буквы`;

export const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;
export const phoneRegExpErrorText = `Неверный формат номера телефона. Должен состоять из 7-10 цифр, может включать в себя символы '-', '(', ')', '+7' или '8'`;

export const genderRegExpErrorText = `Выберите пол`;

export const loginRegExp =
  /^(?:[a-zA-Z0-9_*\-.]+@[a-zA-Z0-9\-*]+\.*[A-Za-z.*-]*\.[A-Za-z]+|(?:8|\+7)[- ]?(?:\(?[0-9]{3}\)?[- ]?)?[0-9\- ]{7,10})$/;
export const loginRegExpErrorText = `Неверный логин. Должен быть в формате адреса электронной почты "example@email.com" или номера телефона, состоящего из 7-10 цифр, может включать в себя символы '-', '(', ')', '+7' или '8'`;

export const passwordReg =
  /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!\\/~_^=+{}[\]-]{8,}$/;

export const getValidateErrors = (validateScheme: ZodType, values: object): DefaultFormErrors => {
  const formErrors: any = {};
  const res = validateScheme.safeParse(values);
  if (!res.success) {
    const errors = res.error.errors;
    // in case it's invalid_union
    if (errors.some((e) => e.code === 'invalid_union')) {
      const unionErrors = errors
        .map((e) => {
          if ('unionErrors' in e) {
            return e.unionErrors.map((ue) => ue.errors);
          } else {
            return [];
          }
        })
        .flat(2);
      if (unionErrors.length) {
        unionErrors.forEach((uerr) => {
          const key = uerr.path.join('.');

          formErrors[key] = uerr.message;
        });
      }
    } else if (errors.length) {
      errors.forEach((err) => {
        const key = err.path.join('.');

        formErrors[key] = err.message;
      });
    }
  }

  return formErrors;
};
