import { FC, useRef, useState, useEffect } from 'react';
import { Box, Divider, Flex, LoadingOverlay, Pagination, Text } from '@mantine/core';
import { PaginatedTable } from '@components/simple-table/PaginatedTable';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { FilterList } from '../../filter-list';
import { TableHeader } from '../../table-header';
import { CSVLinkComponent } from '../../download-button';

import {
  useGetUserActivityQuery,
  useLazyGetUserActivityQuery
} from '@/entities/admin-app/statistics/api';
import { useGetFilterListItemsQuery } from '@/entities/admin-app/users/api';
import { useGetActivityFilters } from './hooks/useGetFilters';
import { useGetColumns } from './hooks/useGetColumns';
import { useStyles as useCommonStyles } from '../../../styles';
import { useStyles } from './styles';
import { IStatsFilters } from '@/containers/pages/statistics/types';
import { transformConditionals } from '@/containers/pages/users/utils';
import {
  convertToFilterConditions,
  transformNumberToSortField,
  transformSortFieldToNumber
} from './utils';
import { formatDate } from '@/lib/utils/date';
import { IUserActivity } from '@/entities/admin-app/statistics';
import ActivityCharts from './charts/ActivityCharts';
import { NoDataText } from '@/components/not-found/components/no-data-text';
import { useTranslation } from 'react-i18next';
import { listSizes } from '@/entities/admin-app/statistics/consts';

const pageSizeOptions = listSizes.map((page) => ({
  value: page.toString(),
  label: page.toString()
}));

const Activity: FC = () => {
  const { t } = useTranslation();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const {
    Total,
    TotalCount,
    filters: actionsFilters,
    Items: actionsUserActivity
  } = useAppSelector((state) => state.statistics.userActivity);

  const initialActiveFilters = actionsFilters.FilterConditions
    ? transformConditionals(actionsFilters.FilterConditions)
    : {};
  const [activeFilters, setActiveFilters] = useState<IStatsFilters>(
    initialActiveFilters as IStatsFilters
  );

  const [rowData, setRowData] = useState<any[]>(actionsUserActivity);
  const [pageSize, setPageSize] = useState<number>(actionsFilters.Count || listSizes[0]);
  const [activePage, setActivePage] = useState(1);
  const scrollRef = useRef<any>(null);
  const { setUserActivity, setUserActivityFilters } = useActions();
  const { filtersMenuList } = useGetActivityFilters();

  const fileName = `users-activity-${formatDate(new Date())}`;

  const { columns } = useGetColumns();

  const [sortParams, setSortParams] = useState<{
    SortOrder: number;
    SortField: number;
  }>({
    SortOrder: actionsFilters?.SortOrder,
    SortField: actionsFilters?.SortField
  });

  const { data, error, isLoading, isSuccess } = useGetUserActivityQuery({
    FilterConditions: actionsFilters.FilterConditions ?? [],
    Query: actionsFilters?.Query,
    Count: pageSize,
    Filters: {},
    Offset: actionsFilters?.Offset,
    SortOrder: actionsFilters?.SortOrder,
    SortField: actionsFilters?.SortField
  });

  const [getUserActivity, { data: csvData, isLoading: csvIsLoading }] =
    useLazyGetUserActivityQuery();

  const formatCsvData = (csvData: IUserActivity[] | undefined) => {
    return csvData?.map((element: IUserActivity) => {
      const formatedDate = formatDate(element.Date);
      return {
        ...element,
        Date: formatedDate
      };
    });
  };

  useEffect(() => {
    if (data) {
      setUserActivity(data);
    }
  }, [data]);

  const handleFilterChange = (updatedFilters: IStatsFilters) => {
    setActiveFilters(updatedFilters);
    scrollRef?.current?.el?.scrollTo(0, 0);
  };

  const defaultTotalValue = {
    Date: '',
    Visitors: 0,
    Auths: 0,
    Documents: 0,
    Files: 0
  };
  const finalRowData = [Total || defaultTotalValue].concat(rowData);
  const resetFilters = () => {
    setUserActivityFilters({
      ...actionsFilters,
      FilterConditions: [],
      Offset: 0,
      Query: ''
    });
    setActivePage(1);
  };
  useEffect(() => {
    setUserActivityFilters({
      ...actionsFilters,
      FilterConditions: convertToFilterConditions(activeFilters),
      Offset: 0
    });
    setActivePage(1);
  }, [activeFilters]);

  useEffect(() => {
    setRowData(actionsUserActivity ?? []);
  }, [actionsUserActivity]);

  useEffect(() => {
    setUserActivityFilters({
      ...actionsFilters,
      Offset: 0,
      SortField: sortParams?.SortField,
      SortOrder: sortParams?.SortOrder
    });
    setActivePage(1);
  }, [sortParams]);

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setActivePage(1);
    setUserActivityFilters({
      ...actionsFilters,
      Offset: 0,
      Count: value
    });
  };

  const handleChangeActivePage = (value: number) => {
    setActivePage(value);
    setUserActivityFilters({
      ...actionsFilters,
      Offset: (value >= 1 ? value - 1 : 0) * pageSize
    });
  };

  const handleSortTable = (payload: any) => {
    setSortParams({
      SortOrder: payload.SortOrder === true ? 1 : 0,
      SortField: transformSortFieldToNumber(payload.SortField)
    });
  };
  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  return (
    <Box className={classes.wrapper} mr="md">
      <Flex direction="column" className={classes.container} my="md" px="md" py="md">
        <Text color="#101828" fw="600">
          {t('statistics.activity')}
        </Text>
        <Box className={classes.listWrapper}>
          <FilterList
            filtersMenuList={filtersMenuList}
            activeFilters={activeFilters}
            onFilterChange={handleFilterChange}
            onFilterReset={setActiveFilters}
            onLoadData={useGetFilterListItemsQuery}
          />
        </Box>
        <Divider className={classes.divider} mx="-md" mb="sm" />
        {isSuccess && TotalCount ? <ActivityCharts /> : null}
        {finalRowData.length && TotalCount ? (
          <>
            <TableHeader
              handleChangePageSize={handleChangePageSize}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              isLoading={isLoading}
              startOffset={actionsFilters.Offset + 1}
              endOffset={Math.min(actionsFilters.Offset + pageSize, TotalCount)}
              totalCount={TotalCount}
              showButton={true}
              buttonComponent={
                <CSVLinkComponent
                  text={t('statistics.export')}
                  filename={fileName}
                  columns={columns}
                  csvData={formatCsvData(csvData?.Items)}
                  actionsFilters={{
                    ...actionsFilters,
                    Offset: 0,
                    Count: TotalCount
                  }}
                  getCsvData={getUserActivity}
                  isLoading={csvIsLoading}
                />
              }
            />

            <Box mt="lg">
              <PaginatedTable
                allDataLength={TotalCount}
                rowData={finalRowData}
                columns={columns}
                height="100%"
                width="100%"
                pinnedRows={[0]}
                scrollRef={scrollRef}
                isLoading={isLoading}
                error={error}
                sort={{
                  SortOrder: sortParams.SortOrder !== 0,
                  SortField: transformNumberToSortField(sortParams.SortField)
                }}
                setSort={handleSortTable}
              />
            </Box>

            {TotalCount > pageSize && (
              <Pagination
                className={commonClasses.paginate}
                value={activePage}
                onChange={handleChangeActivePage}
                total={Math.ceil(TotalCount / pageSize)}
                withControls={false}
                withEdges
                size="sm"
                position="center"
                siblings={1}
              />
            )}
          </>
        ) : (
          isSuccess &&
          !isLoading &&
          !TotalCount && (
            <Flex align="center" justify="center" h="350px">
              <NoDataText />
            </Flex>
          )
        )}
        <LoadingOverlay visible={isLoading} />
      </Flex>
    </Box>
  );
};

export default Activity;
