import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  addBtn: {
    flex: 1,
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,
    marginBottom: 'auto',
    '& .mantine-UnstyledButton-root .mantine-Button-root': {
      marginTop: 0
    },
    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  },

  badge: {
    flex: 1,
    paddingRight: 3,
    backgroundColor: theme.colors.backgroundGray[6],
    color: 'black',
    borderRadius: '16px',
    border: 'none',
    textTransform: 'inherit',
    fontSize: '14px',
    padding: theme.spacing.sm,
    fontWeight: 400,
    height: '2.25rem'
  },

  removeButton: {
    marginBottom: '2px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
    '& .mantine-ActionIcon-root': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      [theme.fn.smallerThan('xs')]: {
        alignItems: 'flex-start',
        position: 'absolute',
        top: '50%',
        right: '0',
        transform: 'translate(100%, -50%)'
      }
    },
    '& button:hover': {
      backgroundColor: 'transparent'
    }
  },

  addManagerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gridTemplateColumns: '1fr 50px',
    marginBottom: 'auto'
  }
}));
