import { Box, Loader, Text } from '@mantine/core';
import {
  Dispatch,
  FC,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IUsersData, IUserTableData } from '@/entities/admin-app/users';
import { useGetUsersQuery } from '@/entities/admin-app/users/api';
import { useDebounce } from '@hooks/useDebounce';
import { IQueryTable } from '@/types/api';
import { IUsersInitialFilters } from '@/types/enums/users';
import { Search } from '../../../search/search-input';
import { useStyles } from './styles';
import { UserListItem } from './user-list-item';
import { IFiltersEnumType } from '@/types/enums';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';
import { useTranslation } from 'react-i18next';
import { NoDataText } from '@/components/not-found/components/no-data-text';

interface IUsersPropsSingle {
  setSelectedUsers: (value: IUserTableData) => void;
  multiple: false;
  userId?: number;
  users: IUsersData;
  filters: IQueryTable<IUsersInitialFilters>;
  setFilters: Dispatch<SetStateAction<IQueryTable<IUsersInitialFilters>>>;
  setUsers: Dispatch<SetStateAction<IUsersData>>;
  usersList?: IUserTableData[];
  filial?: IFilialsInfoCommon | null;
}

interface IUsersPropsMultiple {
  setSelectedUsers: (value: number[]) => void;
  multiple: true;
  userId?: number;
  users: IUsersData;
  filters: IQueryTable<IUsersInitialFilters>;
  setFilters: Dispatch<SetStateAction<IQueryTable<IUsersInitialFilters>>>;
  setUsers: Dispatch<SetStateAction<IUsersData>>;
  usersList?: IUserTableData[];
  filial?: IFilialsInfoCommon | null;
}

type IUsersProps = IUsersPropsSingle | IUsersPropsMultiple;

export const Users: FC<IUsersProps> = ({
  multiple,
  setSelectedUsers,
  userId,
  users,
  filters,
  setFilters,
  setUsers,
  usersList,
  filial
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const scrollRef = useRef<any>(null);

  const [search, setSearch] = useState<string>('');
  const { Users, TotalCount } = users;
  const debounced = useDebounce(search, 500);

  const { data, isLoading, isFetching } = useGetUsersQuery({
    Count: 20,
    Offset: filters.Offset || 0,
    SortOrder: 0,
    SortField: 0,
    Filters: filters.Filters,
    Query: debounced,
    FilterConditions: filial
      ? (filters.FilterConditions || []).concat({
          Type: IFiltersEnumType.FilialFilter,
          NumValues: [filial?.Id]
        })
      : filters.FilterConditions
  });

  const [checkedUsers, setCheckedUsers] = useState<number[]>([]);

  useEffect(() => {
    if (multiple) {
      setSelectedUsers(checkedUsers);
    }
  }, [checkedUsers]);

  const handleUserListClick = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      const id = Number(event.currentTarget.dataset.id);
      if (!multiple) {
        const findUser = Users?.find((item) => item.Id === id);
        if (findUser) {
          setSelectedUsers(findUser);
        }
      } else {
        setCheckedUsers((previousUserIds) => {
          if (previousUserIds.includes(id)) {
            return previousUserIds.filter((previousUserId) => id !== previousUserId);
          } else {
            return [...previousUserIds, id];
          }
        });
      }
    },
    [Users]
  );

  const handleLoadMore = () => {
    setFilters((prevState) => ({
      ...prevState,
      Offset: prevState.Offset + 20
    }));
  };

  useEffect(() => {
    if (data) {
      const usersData = filters?.Offset > 0 ? [...Users, ...data.Users] : data.Users;
      setUsers({ ...data, Users: usersData });
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      scrollRef?.current?.el?.scrollTo(0, 0);
    }

    setFilters((prevState) => ({
      ...prevState,
      Query: search,
      Offset: 0
    }));
  }, [search]);

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Search
          setSearch={setSearch}
          className={classes.search}
          fullSize
          value={search}
          placeholder={t('users.searchUsers')}
        />
        <Box sx={{ width: '100%', height: '100%' }}>
          <InfiniteScroll
            ref={scrollRef}
            dataLength={Users?.length || 20}
            next={handleLoadMore}
            hasMore={TotalCount > Users?.length}
            loader={
              <>
                {Boolean(Users?.length) && (
                  <Box className={classes.infiniteScrollMoreData}>
                    <Loader size="sm" />
                  </Box>
                )}
              </>
            }
            className={classes.infiniteScroll}
            height="417px"
          >
            {isLoading || isFetching ? (
              <Box className={classes.loader}>
                <Loader size="sm" />
              </Box>
            ) : null}
            {Users?.length === 0 && search && !isFetching && (
              <Text className={classes.emptyListText}>{t('userNotFound')}</Text>
            )}
            {Users?.length === 0 && !search && !isFetching && <NoDataText />}

            {Users?.length > 0 &&
              !isLoading &&
              Users?.map((item) => {
                return (
                  <UserListItem
                    key={item.Id}
                    item={item}
                    userId={userId}
                    handleClick={handleUserListClick}
                    checkedUsers={checkedUsers}
                    multiple={multiple}
                    usersList={usersList}
                  />
                );
              })}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
};
