import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.backgroundGray[6]
    }
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
    backgroundColor: theme.colors.backgroundGray[6]
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6]
  },

  hideColumn: {
    display: 'none'
  },

  toggleAll: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`
  }
}));
