import React, { SyntheticEvent, FC } from 'react';
import { Box, Checkbox, Text } from '@mantine/core';
import { useStyles } from './styles';

interface IListItemProps {
  checked?: boolean;
  defaultChecked?: boolean;
  label: string;
  handleChange: (event: SyntheticEvent<HTMLDivElement>) => void;
  value: number;
  counter?: number;
}

export const ListItem: FC<IListItemProps> = ({
  defaultChecked,
  checked,
  label,
  value,
  // counter,
  handleChange
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.link}>
      <Checkbox
        data-link={value}
        value={value}
        checked={!!checked}
        onChange={handleChange}
        defaultChecked={defaultChecked}
        label={
          <Box className={classes.checkboxContainer}>
            <Text className={classes.checkbox}>{label}</Text>
            {/* Counter does not work properly when multiple filters  */}
            {/* <Text className={classes.counter}>{counter}</Text> */}
          </Box>
        }
      />
    </Box>
  );
};
