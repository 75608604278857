import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    marginTop: '-15px'
  },
  subtitle: {
    color: theme.colors.gray[6]
  },
  link: {
    padding: 0,
    fontWeight: 400,
    marginLeft: '5px',
    fontSize: '16px',
    height: 'auto',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },
  checkbox: {
    label: {
      color: theme.colors.gray[8],
      paddingLeft: '7px',
      lineHeight: '18px'
    }
  },
  checkboxLink: {
    fontSize: '14px',
    padding: 0,
    fontWeight: 400,
    height: 'auto',
    lineHeight: '18px',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },
  controls: {
    '& .mantine-InputWrapper-error': {
      marginBottom: '10px',
      marginTop: '10px',
      fontSize: '14px',
      position: 'relative'
    },
    '& .mantine-Checkbox-error': {
      paddingLeft: '8px'
    }
  }
}));
