import { createStyles, rem } from '@mantine/core';
import { HEADER_HEIGHT } from './constants';

export const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed'
  },

  dashboard: {
    backgroundColor: '#F5F6F8',
    borderBottom: 'none'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md
  },

  burger: {
    [theme.fn.largerThan('lg')]: {
      display: 'none'
    }
  },

  logo: {
    width: '170px',
    maxWidth: '170px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    [theme.fn.smallerThan('xs')]: {
      width: '120px',
      maxWidth: '120px',
      '.logoTitle': {
        display: 'none'
      },
      img: {
        maxWidth: '100%'
      }
    }
  },

  links: {
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: `calc(100% - 400px)`,
    [theme.fn.smallerThan('lg')]: {
      display: 'none'
    }
  },
  link: {
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',
    textDecoration: 'none',
    borderRadius: theme.radius.sm,
    display: 'block',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    fontWeight: 400,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      textDecoration: 'none'
    },
    [theme.fn.smallerThan('lg')]: {
      height: rem(42),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: 0,
      padding: theme.spacing.md
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
    })
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        color: theme.primaryColor
      }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color
    }
  },
  linksGroup: {
    flexWrap: 'nowrap'
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('lg')]: {
      display: 'none'
    }
  }
}));
