import { z } from 'zod';
import i18n from '@/entities/i18next';
import { limitsLengthFilialFields } from '@/containers/pages/filials/utils';
export const settingsPageValidateScheme = z.object({
  Title: z
    .string()
    .max(limitsLengthFilialFields.Title, {
      message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', {
        count: limitsLengthFilialFields.Title
      })
    })
    .refine((value) => value.trim().length > 0, {
      message: i18n.t('validation.emptyInput')
    })
    .refine((value) => value.length > 0, {
      message: i18n.t('quota.errors.required')
    }),
  Company: z
    .string()
    .max(limitsLengthFilialFields.Company, {
      message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', {
        count: limitsLengthFilialFields.Company
      })
    })
    .refine((value) => value.trim().length > 0, {
      message: i18n.t('validation.emptyInput')
    })
    .refine((value) => value.length > 0, {
      message: i18n.t('quota.errors.required')
    })
    .or(z.literal('')),
  Parent: z
    .object({
      Id: z.number({ invalid_type_error: i18n.t('filials.settings.chooseParent') })
    })
    .nullable(),
  IsParentSettings: z.boolean()
});

export const createFilialSchema = z.object({
  Company: z
    .string()
    .max(limitsLengthFilialFields.Company, {
      message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', {
        count: limitsLengthFilialFields.Company
      })
    })
    .nullable(),
  Title: z
    .string()
    .min(1, { message: i18n.t('filials.isRequiredField') })
    .max(limitsLengthFilialFields.Title, {
      message: i18n.t('validation.maxLengthSymbolsWithCount.symbols', {
        count: limitsLengthFilialFields.Title
      })
    }),
  Parent: z.object({
    Id: z.number({ invalid_type_error: i18n.t('filials.isRequiredField') })
  })
});
