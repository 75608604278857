import { createStyles } from '@mantine/core';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalRoot: modalTheme(theme).root,
  modalContainer: modalTheme(theme).container,
  modalFooter: modalTheme(theme).footer,
  modalCancelBtn: modalTheme(theme).cancelBtn,
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0],
    [theme.fn.largerThan('md')]: {
      height: `calc(100vh - 80px)`
    }
  },

  list: {
    width: 'calc(100vw - 264px)'
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  searchWrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    padding: '0 24px 0 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 0 20px 0'
  },

  createButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: theme.spacing.xs,
    marginLeft: '27px',
    span: {
      height: 'auto',
      marginRight: '8px'
    },
    [theme.fn.smallerThan('lg')]: {
      padding: '15px',
      span: {
        margin: '0'
      }
    }
  },

  createButtonText: {
    [theme.fn.smallerThan('lg')]: {
      display: 'none'
    }
  },
  search: {
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      paddingLeft: '56px',
      height: '45px',
      fontSize: '16px'
    }
  },

  avatar: {
    cursor: 'pointer'
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  simpleNavbar: {
    // marginTop: '54px',
    backgroundColor: theme.colors.backgroundGray[0],
    [theme.fn.smallerThan('md')]: {
      // width: '55px',
      display: 'flex',
      alignItems: 'flex-start',
      marginRight: theme.spacing.xs,
      backgroundColor: 'white'
    }
  },

  burgerMenu: {
    marginRight: '20px',
    [theme.fn.largerThan('md')]: {
      display: 'none'
    }
  },

  headerMenu: {
    paddingLeft: '25px',
    [theme.fn.largerThan('md')]: {
      paddingLeft: '10px'
    }
  },

  rightBox: {
    '& .mantine-Navbar-root': {
      right: '0',
      left: 'auto',
      marginRight: '15px'
    }
  },

  infoSection: {
    marginTop: '54px',
    backgroundColor: theme.colors.backgroundGray[0],
    '@media (max-width: 1200px)': {
      display: 'none',
      width: '0'
    }
  },

  preserveSpaces: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },

  wrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    height: '100%',
    [theme.fn.largerThan('md')]: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      marginLeft: 290,
      marginRight: 290,
      boxSizing: 'border-box'
    },
    '@media (max-width: 1200px)': {
      marginRight: 0
    }
  },

  section: {
    height: `calc(100vh - 80px)`
  }
}));
