import React, { FC, PropsWithChildren, useRef, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Box, Flex, Text, LoadingOverlay, Burger } from '@mantine/core';

import { useActions } from '@hooks/redux/action';

import { ErrorBoundary } from '@components/error-boundary';
import { SimpleNavbar } from '@components/simple-navbar';
import { Layout } from '../../../layout';
import { NavMenu } from './components/nav-menu';
import { InfoSection } from './components/info-section';
import { NotFound } from '@components/not-found';

import { useGetFilialsInfoCommonQuery } from '@/entities/admin-app/filials/api';
import { CompanyPlaceholder } from '@assets/icons';
import { useStyles } from '../styles';

const FilialEdit: FC<PropsWithChildren> = ({ children }) => {
  const containerRef = useRef(null);

  const { setFilial } = useActions();
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const { id = '' } = useParams();

  const {
    isLoading,
    data: filialData,
    error
  } = useGetFilialsInfoCommonQuery({
    id: +id,
    extended: true
  });

  useEffect(() => {
    if (isLoading || !filialData) {
      return;
    }
    setFilial(filialData);
  }, [isLoading, filialData]);

  if ((id !== undefined && isNaN(Number(id))) || error) {
    return <NotFound />;
  }

  return (
    <Layout
      leftNavbarContent={
        <SimpleNavbar
          widthNav={290}
          style={{ flexShrink: 0, borderRight: 'none', position: 'fixed' }}
          className={classes.simpleNavbar}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        >
          <NavMenu setIsOpen={setIsOpen} />
        </SimpleNavbar>
      }
      rightNavbarContent={
        <Box className={classes.rightBox}>
          <SimpleNavbar
            widthNav={290}
            style={{ flexShrink: 0, borderRight: 'none', position: 'fixed' }}
            className={classes.infoSection}
            isOpen={false}
            setIsOpen={setIsOpen}
          >
            <InfoSection containerRef={containerRef} />
          </SimpleNavbar>
        </Box>
      }
    >
      <ErrorBoundary componentTitle="Filial edit page">
        <Box
          className={classes.wrapper}
          ref={containerRef}
          style={{ flexGrow: 1, overflow: 'auto' }}
          mt="md"
        >
          {isLoading ? (
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
          ) : (
            <>
              <Flex mb="lg" align="center" className={classes.headerMenu}>
                <Burger
                  opened={isOpen}
                  onClick={() => setIsOpen(true)}
                  className={classes.burgerMenu}
                  size="sm"
                />
                <CompanyPlaceholder size={24} stroke="#101828" />
                <Text color="#101828" fw={600} ml="xs">
                  {filialData?.Title}
                </Text>
              </Flex>
              <Outlet />
            </>
          )}
        </Box>
        <Box className={classes.section}>{children}</Box>
      </ErrorBoundary>
    </Layout>
  );
};

export default FilialEdit;
