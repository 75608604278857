import { Sx } from '@mantine/core';

export const defaultButtonSx = (sxProps: any) => {
  const defaultValue = {
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: sxProps?.backgroundColor,
      opacity: 0.8
    }
  } as Sx;

  return defaultValue;
};
