import { useCallback } from 'react';
import { showNotification } from '@mantine/notifications';
import { useLdapSettingsSyncMutation } from '@/entities/admin-app/filials/api';

export const ldapSettingsSync = () => {
  const [ldapSync, { isLoading: isLdapSync }] = useLdapSettingsSyncMutation();
  const SyncSettingsLdap = useCallback(async (id: number) => {
    try {
      const res = await ldapSync({
        id: id
      });
      if ('error' in res) {
        showNotification({
          title: `${(res as any)?.error?.data}`,
          message: '',
          autoClose: true,
          color: 'red'
        });
      } else {
        showNotification({
          title: `Сихронизация запущена!`,
          message: '',
          autoClose: true,
          color: 'green'
        });
      }
    } catch (err: any) {
      console.warn('Ошибка синхронезации', err);
    }
  }, []);

  return { SyncSettingsLdap, isLdapSync };
};
