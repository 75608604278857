import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@supports(-webkit-touch-callout: none)': {
      minHeight: '-webkit-fill-available'
    }
  },

  responseContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  desc: {
    lineHeight: '22px',
    color: theme.colors.gray[6],
    margin: '24px 0 32px'
  },

  responsiveInput: {
    [theme.fn.smallerThan('xs')]: {
      width: '100%'
    }
  },

  icon: {
    marginTop: '15px',
    width: '100px',
    height: '100px'
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`
  }
}));
