import { TextInput, SimpleGrid } from '@mantine/core';
import { useStyles } from './styles';

interface ILdapProps {
  LdapIdentifier?: string;
  isLimitedAccess?: boolean;
}
export const Ldap = ({ LdapIdentifier, isLimitedAccess }: ILdapProps) => {
  const { classes } = useStyles();

  return (
    <SimpleGrid className={classes.ldapLabel} cols={3} mt={16} w="100%">
      <TextInput label="Login" required={false} disabled={isLimitedAccess} value={LdapIdentifier} />
    </SimpleGrid>
  );
};
