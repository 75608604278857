import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  filterHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F2F4F7',
    padding: '4px, 20px, 4px, 20px',
    height: '32px',
    gap: 0,
    borderRadius: '54px'
  },

  menuButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  badgeContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '12px'
  },

  badge: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    marginRight: theme.spacing.xs,
    borderRadius: '16px',
    padding: `0px 6px`
  },

  menu: {
    height: '180px',
    marginBottom: '10px'
  },

  dropdownContainer: {
    padding: theme.spacing.sm
  },
  withoutNumber: {
    backgroundColor: theme.colors.mainBlue[9],
    fontSize: '10px',
    lineHeight: '18px',
    marginRight: theme.spacing.xs,
    borderRadius: '50%',
    padding: 0,
    width: '18px',
    height: '18px'
  }
}));
