import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from '@/entities/admin-app/auth/api';
import { directoriesReducer } from '@/entities/admin-app/directories';
import { directoriesApi } from '@/entities/admin-app/directories/api';
import { emailApi } from '@/entities/admin-app/email/api';
import { groupsReducer } from '@/entities/admin-app/groups';
import { groupsApi } from '@/entities/admin-app/groups/api';
import { userInfoReducer } from '@/entities/admin-app/user';
import { userApi } from '@/entities/admin-app/user/api';
import { usersReducer } from '@/entities/admin-app/users';
import { usersApi } from '@/entities/admin-app/users/api';
import { rolesApi } from '@/entities/admin-app/roles/api';
import { rolesReducer } from '@/entities/admin-app/roles';
import { quotasReducer } from '@/entities/admin-app/quotas';
import { quotasApi } from '@/entities/admin-app/quotas/api';
import { modulesApi } from '@/entities/admin-app/modules/api';
import { statisticsReducer } from '@/entities/admin-app/statistics';
import { statisticsApi } from '@/entities/admin-app/statistics/api';
import { filialsApi } from '@/entities/admin-app/filials/api';
import { filialsReducer } from '@/entities/admin-app/filials';
import { registerApi } from '@/entities/admin-app/register/api';
import { errorHandlingMiddleware } from '@/entities/error-handling';
import { commonSettingsApi } from '@/entities/admin-app/commonSettings/api';
import { commonSettingsReducer } from '@/entities/admin-app/commonSettings';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [registerApi.reducerPath]: registerApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [directoriesApi.reducerPath]: directoriesApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [quotasApi.reducerPath]: quotasApi.reducer,
    [modulesApi.reducerPath]: modulesApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [filialsApi.reducerPath]: filialsApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [commonSettingsApi.reducerPath]: commonSettingsApi.reducer,
    userInfo: userInfoReducer,
    users: usersReducer,
    filials: filialsReducer,
    roles: rolesReducer,
    directories: directoriesReducer,
    groups: groupsReducer,
    quotas: quotasReducer,
    statistics: statisticsReducer,
    commonSettings: commonSettingsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      registerApi.middleware,
      usersApi.middleware,
      userApi.middleware,
      directoriesApi.middleware,
      emailApi.middleware,
      groupsApi.middleware,
      quotasApi.middleware,
      modulesApi.middleware,
      statisticsApi.middleware,
      errorHandlingMiddleware,
      filialsApi.middleware,
      rolesApi.middleware,
      commonSettingsApi.middleware
    )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
