import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: theme.spacing.sm,
    width: '100%',
    marginLeft: '-6px'
  },

  text: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'start',
    lineHeight: '20px',
    marginLeft: '12px'
  },

  group: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing.xs} 0px ${theme.spacing.xs} 6px`,
    cursor: 'pointer',
    width: '100%',
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black
    }
  }
}));
