import { LoadingOverlay, Box } from '@mantine/core';
import { useAppSelector } from '@/hooks/redux/redux';
import { useStyles } from './styles';
import { useEffect, useState } from 'react';
import { generatePasswordRegExp } from '@/containers/pages/user/components/sections/general-info/password/utils';
import { useFetchToCrypt } from '@/hooks/useFetchToCrypt';
import { useLazyUpdatePasswordWithCodeQuery } from '@/entities/admin-app/auth/api';
import { useLazyCheckUserWithCodeQuery } from '@/entities/admin-app/auth/api';
import { useParams } from 'react-router-dom';
import { LoginWraper } from '@/components/login';
import { IUpdatePasswordWithCodeEnum } from '@/entities/admin-app/auth/types';
import { isCrypt } from '@/lib/utils/access';
import { useTranslation } from 'react-i18next';
import { InvalidLinkContent } from '@/containers/pages/reser-Password/components/InvalidLinkContent';
import { SuccessResetContent } from '@/containers/pages/reser-Password/components/successResetContent';
import { IPasswordSecurity } from '@/entities/admin-app/filials';
import { ResetPasswordForm } from '@/containers/pages/reser-Password/components/resetPasswordForm';

export const ResetPassword = () => {
  const { t } = useTranslation();
  const { code = '' } = useParams();
  const { classes } = useStyles();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const fetchToCrypt = useFetchToCrypt();
  const [updatePasswordWithCode, { data, isSuccess, isError }] =
    useLazyUpdatePasswordWithCodeQuery();
  const [checkUserWithCode, { data: checkUserCodeData }] = useLazyCheckUserWithCodeQuery();
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const [passwordStrengthOptions, setPasswordStrengthOptions] = useState<IPasswordSecurity>({});
  const [passwordRegexp, setPasswordRegexp] = useState<RegExp>(generatePasswordRegExp({}));

  const handleUpdatePassword = async (values: any) => {
    if (isCrypt(Settings)) {
      await fetchToCrypt(values.Password, (encryptedBase64) => {
        updatePasswordWithCode({ UserPassword: '', CryptedPassword: encryptedBase64, Code: code });
      });
    } else {
      updatePasswordWithCode({ UserPassword: values.Password, Code: code });
    }
    return;
  };

  useEffect(() => {
    checkUserWithCode({ Code: code }).then(() => {
      setIsRequestCompleted(true);
    });
  }, [code, checkUserWithCode]);

  useEffect(() => {
    if (checkUserCodeData) {
      setIsCodeValid(checkUserCodeData?.IsActive);
      setPasswordStrengthOptions({
        MinLength: checkUserCodeData?.MinLength,
        UseCapitalLetters: checkUserCodeData.UseCapitalLetters,
        UseDigital: checkUserCodeData?.UseDigital,
        UseSpecialCharacters: checkUserCodeData.UseSpecialCharacters,
        UseAllowedSpecialCharacters: checkUserCodeData.UseAllowedSpecialCharacters,
        UseLowLetter: checkUserCodeData.UseLowLetter
      });
    }
  }, [checkUserCodeData]);

  useEffect(() => {
    if (passwordStrengthOptions) {
      const regexp = generatePasswordRegExp({ options: passwordStrengthOptions });
      setPasswordRegexp(regexp);
    }
  }, [passwordStrengthOptions]);

  return (
    <LoginWraper
      title={
        !isRequestCompleted || !isCodeValid || isSuccess || isError
          ? ''
          : t('passwordReset.passwordRecovery')
      }
    >
      <Box className={classes.controls}>
        {!isRequestCompleted ? (
          <LoadingOverlay visible={true} overlayBlur={10} />
        ) : !isCodeValid ? (
          <InvalidLinkContent />
        ) : isCodeValid && isSuccess ? (
          data?.Status === IUpdatePasswordWithCodeEnum.Changed ? (
            <SuccessResetContent />
          ) : null
        ) : (
          <ResetPasswordForm
            passwordRegexp={passwordRegexp}
            handleUpdatePassword={handleUpdatePassword}
            passwordStrengthOptions={passwordStrengthOptions}
          />
        )}
      </Box>
    </LoginWraper>
  );
};
