import { IHeaderList } from '@components/header';

import { RoleTypesEnum, UserStatusEnum } from '@/types/enums/user';
import { IFilterConditionItem } from '@/types/api';
import { IRowData, IUsersSortField } from '@components/simple-table/types';
import { ISortedListByEmailSettings } from '@/entities/admin-app/users';
import { setToEndOFDate } from '../filials/utils';

interface IIndicatorColors {
  color: string;
  label: string;
  fullLabel: string;
  colorBadge: string;
}

export const getIndicatorByUserStatus = (status: UserStatusEnum): IIndicatorColors => {
  let color, label, fullLabel, colorBadge;
  switch (status) {
    case UserStatusEnum.Ban:
      color = 'red';
      label = 'B';
      fullLabel = 'Заблокирован';
      colorBadge = 'red';
      break;
    case UserStatusEnum.Deleted:
      color = 'transparent';
      label = '🚫';
      fullLabel = 'Удален';
      colorBadge = 'red';
      break;
    case UserStatusEnum.Disabled:
      color = '#6a655f';
      label = 'D';
      fullLabel = 'Отключен';
      colorBadge = 'dark';
      break;
    case UserStatusEnum.Active:
      color = '#228be6';
      label = 'A';
      fullLabel = 'Активный';
      colorBadge = '';
      break;
    case UserStatusEnum.Invited:
      color = '#fab005';
      label = 'I';
      fullLabel = 'Приглашенный';
      colorBadge = 'yellow';
      break;
    default:
      color = '';
      label = '';
      fullLabel = '';
      colorBadge = '';
  }
  return { color, label, fullLabel, colorBadge };
};

interface IBadgeColors {
  color: string;
  status: string;
}

export const getColorsByRole = (role: RoleTypesEnum): IBadgeColors => {
  let color, status;
  switch (role) {
    case RoleTypesEnum.User:
      color = 'orange';
      status = 'USR';
      break;
    case RoleTypesEnum.Admin:
      color = 'violet';
      status = 'ADM';
      break;
    case RoleTypesEnum.SuperUser:
      color = 'green';
      status = 'SUP';
      break;
    case RoleTypesEnum.Guest:
      color = 'cyan';
      status = 'GST';
      break;
    case RoleTypesEnum.All:
      color = 'indigo';
      status = 'ALL';
      break;
    default:
      color = '';
      status = '';
  }
  return { color, status };
};

export const mainHeaderList: IHeaderList[] = [
  { label: 'Пользователи', link: '/users', active: false },
  { label: 'Роли', link: '/roles', active: false },
  { label: 'Подразделения', link: '/filials', active: false },
  { label: 'Статистика', link: '/statistics', active: false }
];

export const getHeaderListByRole = (isSuperAdmin?: boolean): IHeaderList[] => {
  return isSuperAdmin
    ? [...mainHeaderList, { label: 'Общие настройки', link: '/settings', active: false }]
    : mainHeaderList;
};

export const convertToFilterConditions = (
  activeFilters: Record<number, number[] | Date[]>
): IFilterConditionItem[] => {
  const filterObjects: IFilterConditionItem[] = [];

  for (const [key, value] of Object.entries(activeFilters)) {
    const filterObject: IFilterConditionItem = {
      Type: Number(key)
    };

    if (key === '11' || key === '12' || key === '13') {
      const [from, to] = value as Date[];
      filterObject.DateRange = {
        From: from.toISOString(),
        To: setToEndOFDate(new Date(to))
      };
    } else if (key === '15') {
      const [from, to] = value as number[];
      filterObject.NumRange = {
        From: from,
        To: to
      };
    } else {
      filterObject.NumValues = value as number[];
    }

    filterObjects.push(filterObject);
  }

  return filterObjects;
};

export const transformConditionals = (filters: IFilterConditionItem[]) => {
  const transformedFilters: Record<number, number[] | Date[]> = {};

  filters.forEach((filter) => {
    const { Type, NumValues, DateRange, NumRange } = filter;
    if (NumValues) {
      transformedFilters[Type] = NumValues;
    } else if (DateRange) {
      transformedFilters[Type] = [new Date(DateRange?.From), new Date(DateRange?.To)] as Date[];
    } else if (NumRange) {
      transformedFilters[Type] = [NumRange.From, NumRange.To];
    }
  });

  return transformedFilters;
};

export const getActiveFiltersLengths = (
  activeFilters: Record<number, number[] | Date[]>
): number => {
  let totalLength = 0;
  Object.entries(activeFilters).forEach(([key, values]) => {
    if (
      parseInt(key) === 11 ||
      parseInt(key) === 12 ||
      parseInt(key) === 13 ||
      parseInt(key) === 15
    ) {
      totalLength += 1;
    } else {
      totalLength += values.length;
    }
  });
  return totalLength;
};

export const transformNumberToUsersSortField = (value: IUsersSortField): keyof IRowData => {
  return value in IUsersSortField ? IUsersSortField[value] : IUsersSortField[IUsersSortField?.None];
};

export const transformUsersSortFieldToNumber = (
  value: keyof typeof IUsersSortField
): IUsersSortField => {
  return getValueByKeyForNumberEnum(value);
};

function getValueByKeyForNumberEnum(value: keyof typeof IUsersSortField) {
  const valueFromKey = Object.entries(IUsersSortField).find(([key]) => key === value)?.[1];
  return typeof valueFromKey === 'number' && valueFromKey in IUsersSortField
    ? valueFromKey
    : IUsersSortField.None;
}

export function getUsersIdBySelect(data: ISortedListByEmailSettings[]): number[] {
  const userIds: number[] = [];
  data.forEach((customer) => {
    if (customer.HasSettings) {
      customer.Users.forEach((user) => {
        if (user.isChecked && !user.isDisabled) {
          userIds.push(user.id);
        }
      });
    }
  });

  return userIds;
}
