import { ActionIcon, Box, Indicator, InputProps, SimpleGrid, Text } from '@mantine/core';
import { useContext, useState } from 'react';
import { CloseIcon } from '@assets/icons';
import { useStyles } from './styles';
import { TextFieldInput } from '@components/fields/text-field-input';
import { SectionTemplate } from '../../section-template';
import { CreateUserContext } from '../../../create-user';
import { AvatarWithOverlay } from '@components/avatar-with-overlay';
import { AvatarEditModal } from '../../../create-user/modal/avatar-edit';
import { PanelsEnum } from '@/types/enums/user';
import { ValidateTextInput } from '@/containers/pages/user/components/validateFields/validateTextInput';
import { StatusSelect } from '@/containers/pages/user/components/sections/general-info/status-select';
import { Gender } from '@/components/fields/gender';
import { Address } from '@/components/fields/address';
import { ValidatePhoneInput } from '../../validateFields/validatePhoneInput';
import { Phone } from '@/components/fields/phone';
import { DatePicker } from '@components/fields/date-picker';
import { isSaaS } from '@/lib/utils/access';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/redux/redux';

export const GeneralInfo = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { form } = useContext(CreateUserContext);
  const { Settings } = useAppSelector((state) => state.userInfo);
  const { Common, Status } = form.values;
  const CredentialsFormKey = 'Credentials';
  const CommonFormKey = 'Common';

  const [editAvatar, setEditAvatar] = useState<boolean>(false);
  const [preview, setPreview] = useState<string>('');

  const fullName = `${Common.SurName ?? ''} ${Common.FirstName ?? ''} ${Common.MiddleName ?? ''}`;
  const isMobile = useMediaQuery('(max-width: 576px)');

  const handleCloseModalEditAvatar = () => {
    setEditAvatar(false);
  };

  const openAvatarModal = () => {
    setEditAvatar(true);
  };

  const handleDeleteAvatar = () => {
    setPreview('');
    form.setFieldValue('AvatarId', null);
  };

  const label = preview ? (
    <ActionIcon size="md" variant="transparent" onClick={() => handleDeleteAvatar()}>
      <CloseIcon fill="#667085" />
    </ActionIcon>
  ) : null;
  return (
    <>
      <SectionTemplate dataSection={PanelsEnum.status}>
        <Box className={classes.root}>
          <Box className={classes.photo}>
            <Indicator label={label} size={13} offset={10} position="top-end" color="transparent">
              <AvatarWithOverlay
                size={isMobile ? 90 : 124}
                radius={62}
                src={preview}
                handleClick={openAvatarModal}
              />
            </Indicator>
          </Box>
          <Box className={classes.mainInfo}>
            <Text className={classes.name}>{fullName}</Text>
            <StatusSelect
              label={t('users.status')}
              placeholder={t('profile.chooseStatus')}
              fieldName="Status"
              form={form}
              value={Status}
            />
          </Box>

          <AvatarEditModal
            isOpened={editAvatar}
            title={t('profile.profilePhoto')}
            onClose={handleCloseModalEditAvatar}
            preview={preview}
            setPreview={setPreview}
          />
        </Box>
      </SectionTemplate>
      <SectionTemplate dataSection={PanelsEnum.credentials} title={t('profile.credentials')}>
        <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
          {!isSaaS(Settings) ? (
            <ValidateTextInput
              fieldName={`${CredentialsFormKey}.Login`}
              label={t('profile.login')}
              form={form}
              withAsterisk
            />
          ) : null}
          <ValidateTextInput
            fieldName={`${CredentialsFormKey}.Email`}
            label={t('login.login')}
            form={form}
            withAsterisk={isSaaS(Settings)}
          />
        </SimpleGrid>
      </SectionTemplate>

      <SectionTemplate
        dataSection={PanelsEnum.personalData}
        title={t('profile.generalInformation')}
      >
        <SimpleGrid cols={isMobile ? 1 : 2} w="100%">
          <TextFieldInput
            fieldName={`${CommonFormKey}.SurName`}
            label={t('profile.surName')}
            form={form}
            withAsterisk
          />
          <TextFieldInput
            fieldName={`${CommonFormKey}.FirstName`}
            label={t('profile.firstName')}
            form={form}
            withAsterisk
          />
        </SimpleGrid>
        <SimpleGrid cols={isMobile ? 1 : 2} mt={16} w="100%">
          <TextFieldInput
            fieldName={`${CommonFormKey}.MiddleName`}
            label={t('profile.middleName')}
            form={form}
          />
          <Gender
            fieldName={`${CommonFormKey}.Gender`}
            form={form}
            defaultValue={String(Common.Gender)}
            clearable={true}
          />
        </SimpleGrid>
        <SimpleGrid cols={isMobile ? 1 : 2} w="100%" mt={16}>
          <DatePicker
            fieldName={`${CommonFormKey}.BirthDate`}
            placeholder={t('profile.chooseBirthDate')}
            label={t('profile.birthDate')}
            form={form}
            clearable={true}
          />
          <Address
            fieldName={`${CommonFormKey}.Address`}
            label={t('profile.address')}
            placeholder={t('profile.addAddress')}
            form={form}
          />
        </SimpleGrid>
        <SimpleGrid cols={isMobile ? 1 : 2} w="100%" mt={16}>
          <ValidatePhoneInput
            form={form}
            mask="+7 (999) 999-99-99"
            label={t('profile.phone')}
            fieldName={`${CommonFormKey}.Phone`}
          />

          <Phone
            label={t('profile.additionalPhone')}
            inputProps={form.getInputProps(`${CommonFormKey}.AdditionalPhone`) as InputProps}
            form={form}
            fieldName={`${CommonFormKey}.AdditionalPhone`}
          />
        </SimpleGrid>
      </SectionTemplate>
    </>
  );
};
