import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0],
    [theme.fn.largerThan('md')]: {
      height: `calc(100vh - 80px)`
    }
  },

  list: {
    width: 'calc(100vw - 264px)'
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  Wrapper: {
    backgroundColor: theme.colors.backgroundGray[0],
    padding: '0 24px 0 24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 0 20px 0'
  },
  TableWrapper: {
    padding: '0 24px 0 24px',
    width: '100%'
  },

  search: {
    marginRight: '27px',
    '& input': {
      backgroundColor: theme.colors.backgroundGray[6],
      paddingLeft: '56px',
      height: '45px',
      fontSize: '16px'
    }
  },

  avatar: {
    cursor: 'pointer'
  },

  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}));
