import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  badge: {
    backgroundColor: theme.colors.backgroundGray[6],
    color: '#344054',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    marginRight: theme.spacing.xs,
    borderRadius: '16px',
    cursor: 'pointer',
    padding: `0px ${theme.spacing.xs}`,

    '& .mantine-Badge-rightSection': {
      display: 'flex'
    }
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none'
  }
}));
