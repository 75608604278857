import { theme } from '@/theme/global-theme';
import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    // '& .mantine-Drawer-body': {
    //   maxWidth: '274px'
    // },
    '& .mantine-Drawer-content': {
      maxWidth: 'auto',
      boxShadow: '-32px 0px 80px 0px rgba(0, 0, 0, 0.10)',
      '@media (min-width: 574px)': {
        maxWidth: '320px'
      }
    },
    '& .mantine-Drawer-body': {
      padding: theme.spacing?.sm
    }
  },

  title: {
    width: '100%'
  },

  description: {
    width: '100%'
  }
}));
