import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    overflowX: 'hidden',
    padding: theme.spacing.md
  },

  control: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing.sm
  },

  controlTextInfo: {
    color: theme.colors.brandGray[0],
    fontSize: '12px',
    lineHeight: '15px',
    marginTop: theme.spacing.md
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#F9FAFB',
    height: '63px',
    padding: `0px ${theme.spacing.md}`,
    borderTop: '1px solid #F2F4F7'
  },

  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  }
}));
