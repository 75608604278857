import { showNotification } from '@mantine/notifications';
import { extractErrorCode } from '@/lib/utils/extractFetchErrors';
import { generateErrorMessage } from '@/lib/utils/generateErrorMessage';
import { t } from 'i18next';
import { NotificationProps } from '@mantine/core/lib/Notification/Notification';

interface IGetAllNotification extends NotificationProps {
  error: any;
  isSuccess: boolean;
  isError: boolean;
  successMessage?: string;
  successTitle?: string;
  errorMessage?: string;
  errorTitle?: string;
}

export const universalNotification = ({
  error,
  isSuccess,
  isError,
  successMessage,
  successTitle,
  errorTitle,
  errorMessage,
  ...props
}: IGetAllNotification) => {
  try {
    if (isSuccess && (successMessage || successTitle)) {
      showNotification({
        ...props,
        title: successTitle,
        message: successMessage,
        autoClose: true,
        color: 'green',
        style: { zIndex: 1003 }
      });
    } else if (isError) {
      const codeError = extractErrorCode(error);
      showNotification({
        ...props,
        title: errorTitle?.length
          ? errorTitle
          : codeError
          ? generateErrorMessage(codeError)
          : t(`requestsErrors.unknownError`),
        message: errorMessage?.length ? errorMessage : '',
        autoClose: true,
        color: 'red',
        style: { zIndex: 1003 }
      });
    }
  } catch (e) {
    const error = e as Error;
    showNotification({
      ...props,
      title: `${error?.name}`,
      message: error?.message,
      autoClose: true,
      color: 'yellow',
      style: { zIndex: 1003 }
    });
  }
};
