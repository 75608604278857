import React, { FC, useCallback, useEffect } from 'react';
import { Button, Flex, SimpleGrid, TextInput, ColorInput } from '@mantine/core';
import { useStyles } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { CommonSectionTemplate } from '@components/section-template';
import {
  ICommonSettingsEnum,
  ICommonViewSettings,
  IUploadedFileResponse
} from '@/entities/admin-app/commonSettings';
import { brandingSettings } from '@/mock-data/commonSettings';
import { useSaveCommonSettings } from '@/containers/pages/commonSettings/hooks/useSaveCommonSettings';
import LogoField from '@/containers/pages/commonSettings/components/logoField';
import { validateSchemeBrandingCommon } from '@/containers/pages/commonSettings/schemes/validate';

interface ICompanyName {
  CommonViewSettings: ICommonViewSettings;
}

interface IFormData {
  CompanyName: string;
  MainLogoUrl: string;
  BrandColor: string;
  MainLogoId: number | null;
}
export const CommonSection: FC<ICompanyName> = ({ CommonViewSettings }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const updateSettings = useSaveCommonSettings();

  const form = useForm<IFormData>({
    initialValues: {
      CompanyName: '',
      MainLogoUrl: '',
      BrandColor: '',
      MainLogoId: null
    },
    validateInputOnChange: true,
    validate: (values: IFormData): FormErrors =>
      getValidateErrors(validateSchemeBrandingCommon, values)
  });

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    if (CommonViewSettings) {
      const formData = {
        CompanyName: CommonViewSettings.Name,
        BrandColor: CommonViewSettings.BrandColor || '',
        MainLogoUrl: CommonViewSettings.MainLogoUrl || '',
        MainLogoId: CommonViewSettings.MainLogoId
      };
      form.setValues(formData);
      form.resetDirty(formData);
    }
  }, [CommonViewSettings]);
  const handleSubmit = async (values: IFormData) => {
    try {
      await updateSettings(ICommonSettingsEnum.CommonViewSettings, {
        CommonViewSettings: {
          Name: values.CompanyName,
          BrandColor: values.BrandColor,
          MainLogoUrl: values.MainLogoUrl,
          MainLogoId: values.MainLogoId
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateLogo = useCallback((data: IUploadedFileResponse) => {
    form.setFieldValue(
      'MainLogoUrl',
      `${
        data?.Url
          ? data?.Url
          : data?.Id
          ? 'https://cddisk.r7-office.ru/api/v1/files/stream?id=$' + data?.Id
          : ''
      }`
    );
    form.setFieldValue('MainLogoId', data?.Id);
  }, []);

  return (
    <CommonSectionTemplate title={t('common')}>
      <SimpleGrid cols={2} w="100%" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            className={classes.textFieldInput}
            label={t('commonSettings.companyName')}
            withAsterisk
            {...form.getInputProps(`CompanyName`)}
          />
          <LogoField
            logoUrl={form.getInputProps('MainLogoUrl').value}
            handleUpdateLogo={handleUpdateLogo}
            dimensions={brandingSettings.common.logoDimensions}
            allowedMimeType={brandingSettings.common.allowedMimeType}
          />
          <ColorInput
            className={classes.textFieldInput}
            label="Брендовый цвет"
            {...form.getInputProps(`BrandColor`)}
          />
          <Flex className={classes.sectionControls} w="100%">
            <Button
              disabled={isDisabled}
              className={classes.submitButton}
              size="sm"
              radius={8}
              type="submit"
              mt="md"
            >
              {t('save')}
            </Button>

            {/*{TeamSettings?.IsEnabled ? (*/}
            {/*  <Button*/}
            {/*    size="sm"*/}
            {/*    radius={8}*/}
            {/*    color="green"*/}
            {/*    mt="md"*/}
            {/*    // rightIcon={<IconRefresh size={24} className={isTeamSync ? classes.rotate : ''} />}*/}
            {/*    onClick={() => handleChangeDefaultLogo()}*/}
            {/*    disabled={isDisabled}*/}
            {/*  >*/}
            {/*    /!*{t('sync')}*!/*/}
            {/*    {t('commonSettings.useAsLogo')}*/}
            {/*  </Button>*/}
            {/*) : null}*/}
          </Flex>
        </form>
      </SimpleGrid>
    </CommonSectionTemplate>
  );
};
