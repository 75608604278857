import React, { FC, useContext } from 'react';
import { Group, Overlay, Popover, PopoverProps, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IListItem } from '@/types';
import { UserInfoMainContext } from '@/containers/pages/users/components/user-info-main/panels';
import { useAppSelector } from '@/hooks/redux/redux';
import { generateDataCyValue } from '@/utils/kebabToCamel';

interface IPopoverMenuMenu extends PopoverProps {
  list: IListItem[];
  email?: string;
  isConfirmed?: boolean;
  onClickItem?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  overflow?: boolean;
}
export interface IListItemPopover extends IListItem {
  color?: string;
}

export const PopoverMenu: FC<IPopoverMenuMenu> = ({
  children,
  email,
  isConfirmed,
  list,
  onClickItem,
  overflow,
  ...props
}) => {
  const { classes } = useStyles();
  const { userInfoCommon } = useContext(UserInfoMainContext);
  const currentUser = useAppSelector((state) => state.userInfo.User);

  return (
    <>
      {props.opened && overflow && <Overlay opacity={0} fixed />}
      <Popover {...props}>
        <Popover.Target>{children}</Popover.Target>
        <Popover.Dropdown className={classes.popover} data-cy="popoverMenu">
          {list
            .filter((item: IListItemPopover) => {
              return (
                !(item?.value === 'activate-email' && (!email?.length || isConfirmed)) &&
                !(item?.value === 'write-letter' && !email?.length) &&
                !(
                  item?.value === 'terminate-all' &&
                  userInfoCommon &&
                  (!userInfoCommon?.Connections.length ||
                    (userInfoCommon?.Id === currentUser?.Id &&
                      userInfoCommon?.Connections.length === 1))
                )
              );
            })
            .map((item: IListItemPopover, index: number) => {
              return (
                <Group
                  key={index}
                  data-value={item.value}
                  onClick={onClickItem}
                  className={classes.itemMenu}
                  data-cy={`${generateDataCyValue(item)}`}
                >
                  {item?.icon}
                  <Text color={item?.color || ''} ml={12}>
                    {item.label}
                  </Text>
                </Group>
              );
            })}
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
