import { ReactNode, FC } from 'react';
import { IconSettings } from '@tabler/icons';
import {
  CalendarExtraIcon,
  DriveIcon,
  DrawIcon,
  FileIcon,
  FilialsIcon,
  GroupsIcon,
  MailIcon,
  ShieldIcon,
  UsersIcon,
  ActivityIcon,
  SettingsIcon,
  RocketIcon,
  ContactsIcon,
  ProjectsIcon
} from '@assets/icons';
import { IListItem } from '@/types';
import { PanelsEnum } from '@/types/enums/user';
import { EmailInfoPanelsEnum, DrawInfoPanelsEnum } from '@/types/enums/modules';
import i18n from '@/entities/i18next';

import { ROUTES } from '@/containers/routes/constants';

export interface INavbarMenuItem {
  label: string;
  checked: boolean;
  value: number;
  counter: number;
}

export interface INavbarMenu {
  label: string;
  icon: ReactNode | FC | null;
  value: string;
  counter?: number;
  initiallyOpened?: boolean;
  list?: INavbarMenuItem[];
  hideCollapse?: boolean;
}

export interface IMainNavbarMenu {
  pages: INavbarMenu[];
  typeUsers: INavbarMenuItem[];
}

export const controlMenuPages: INavbarMenu[] = [
  {
    label: i18n.t('statistics.activity'),
    icon: ActivityIcon,
    value: ROUTES.statistics.links.activity
  },
  {
    label: i18n.t('statistics.disk_space'),
    icon: DriveIcon,
    value: ROUTES.statistics.links.diskSpace
  },
  { label: i18n.t('statistics.audit'), icon: FileIcon, value: ROUTES.statistics.links.audit }
];

export const menuProfilePage: readonly INavbarMenu[] = [
  {
    label: i18n.t('profile.personalData'),
    icon: UsersIcon,
    value: ROUTES.profile.pages.personalData
  },
  { label: i18n.t('profile.company'), icon: FilialsIcon, value: ROUTES.profile.pages.filials },
  { label: i18n.t('profile.safety'), icon: ShieldIcon, value: ROUTES.profile.pages.safety },
  {
    label: i18n.t('profile.emailAccount'),
    icon: MailIcon,
    value: ROUTES.profile.pages.mailAccount
  },
  { label: i18n.t('filials.diskSpace.disk'), icon: DriveIcon, value: ROUTES.profile.pages.disk }
];
export const menuSettingsPage: readonly INavbarMenu[] = [
  {
    label: i18n.t('filials.branding'),
    icon: RocketIcon,
    value: ROUTES.commonSettings.pages.branding
  },
  {
    label: i18n.t('filials.integrations'),
    icon: SettingsIcon,
    value: ROUTES.commonSettings.pages.integrations
  }
  // {
  //   label: 'Кастомизация',
  //   icon: CustomisationIcon,
  //   value: ROUTES.commonSettings.pages.customisation
  // }
];

export const menuPages: INavbarMenu[] = [
  { label: i18n.t('filials.diskSpace.table.users'), icon: <UsersIcon />, value: 'users' },
  { label: i18n.t('filials.groups'), icon: <GroupsIcon />, value: 'roles' },
  { label: i18n.t('settings'), icon: <IconSettings />, value: 'settings' }
];

export const userInfoSections: IListItem[] = [
  { label: i18n.t('users.status'), value: PanelsEnum.status },
  { label: i18n.t('profile.credentials'), value: PanelsEnum.credentials },
  { label: i18n.t('profile.generalInformation'), value: PanelsEnum.personalData },
  { label: i18n.t('profile.unit'), value: PanelsEnum.company },
  { label: i18n.t('profile.position'), value: PanelsEnum.positions },
  { label: i18n.t('profile.roles'), value: PanelsEnum.roles },
  { label: i18n.t('profile.mailAccounts'), value: PanelsEnum.mailAccount },
  { label: i18n.t('profile.retentionPolicy'), value: PanelsEnum.disk }
];

export const modulesMenuPages: INavbarMenu[] = [
  {
    label: i18n.t('filials.authorization.mail'),
    icon: MailIcon,
    value: ROUTES.filials.edit.pages.modules.email
  },
  {
    label: i18n.t('filials.calendar'),
    icon: CalendarExtraIcon,
    value: ROUTES.filials.edit.pages.modules.calendar
  },
  { label: i18n.t('filials.draw'), icon: DrawIcon, value: ROUTES.filials.edit.pages.modules.draw },
  {
    label: i18n.t('filials.contacts'),
    icon: ContactsIcon,
    value: ROUTES.filials.edit.pages.modules.contacts
  },
  {
    label: i18n.t('filials.projects'),
    icon: ProjectsIcon,
    value: ROUTES.filials.edit.pages.modules.projects
  }
];

export const driveInfoSections = [
  // { label: 'Квоты', value: DriveInfoPanelsEnum.quotas },
  // { label: 'Внешние ссылки', value: DriveInfoPanelsEnum.externalLinks }
] as const;

export const emailInfoSections = [
  { label: i18n.t('filials.modules.rolesAccess'), value: EmailInfoPanelsEnum.byRoles },
  { label: i18n.t('filials.modules.userAccess'), value: EmailInfoPanelsEnum.byUsers }
] as const;

export const calendarInfoSections = [
  { label: i18n.t('filials.modules.rolesAccess'), value: EmailInfoPanelsEnum.byRoles },
  { label: i18n.t('filials.modules.userAccess'), value: EmailInfoPanelsEnum.byUsers }
] as const;

export const drawInfoSections = [
  { label: i18n.t('filials.modules.rolesAccess'), value: DrawInfoPanelsEnum.byRoles },
  { label: i18n.t('filials.modules.userAccess'), value: DrawInfoPanelsEnum.byUsers }
] as const;
export const contactsInfoSections = [
  { label: i18n.t('filials.modules.rolesAccess'), value: DrawInfoPanelsEnum.byRoles },
  { label: i18n.t('filials.modules.userAccess'), value: DrawInfoPanelsEnum.byUsers }
] as const;
