import { Box } from '@mantine/core';
import React, { FC, useContext } from 'react';
import { UserInfoMainContext } from '..';
import { getGenderString } from '@/lib/utils';
import { formatDate } from '@/lib/utils/date';
import { PanelsEnum } from '@/types/enums/user';
import { TextField } from '../../components';
import { HeaderPanel } from '../../components/panel-header';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export const MoreInfoPanel: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { userInfoCommon } = useContext(UserInfoMainContext);

  return (
    <>
      <HeaderPanel
        title={t('profile.additionalInfo')}
        activePanel={PanelsEnum.additionalInformation}
      />
      <Box className={classes.content}>
        {/* <TextField
          title="Местоположение (Офис)"
          description={userInfoCommon?.Common?.OfficeAddress?.Name}
        /> */}
        <TextField
          title={t('profile.birthDate')}
          description={formatDate(userInfoCommon?.Common?.BirthDate)}
        />
        <TextField
          title={t('profile.gender')}
          description={getGenderString({ gender: userInfoCommon?.Common?.Gender, t })}
        />
        <TextField title={t('profile.homePhone')} description={userInfoCommon?.Common?.Phone} />
        {/* <TextField title="Адрес (индекс)" description={userInfoCommon?.Common?.PostIndex} /> */}
        <TextField title={t('profile.address')} description={userInfoCommon?.Common?.Address} />
        {/* <TextField title="Адрес (страна)" description={userInfoCommon?.Common?.Country} /> */}
        <TextField
          title={t('dateOfCreation')}
          description={formatDate(userInfoCommon?.Common?.DateOfCreate)}
        />
        <TextField
          title={t('profile.lastEntryDate')}
          description={formatDate(userInfoCommon?.Common?.LastEntryDate)}
        />
      </Box>
    </>
  );
};
