import { Box, Burger } from '@mantine/core';
import { ErrorBoundary } from '@components/error-boundary';
import { Layout } from '../../../layout';
import { SimpleNavbar } from '@components/simple-navbar';
import { useStyles } from './styles';
import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAppSelector } from '@hooks/redux/redux';
import { Outlet, useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from '@/entities/admin-app/user/api';
import { IUserInfo } from '@/entities/admin-app/user';
import { isAdmin } from '@/lib/utils/access';
import { initForm } from '@/containers/pages/user/consts/initValues';
import { NavMenu } from './components/nav-menu';
import { NotFound } from '@/components/not-found';

export interface IEditUserContext {
  currentId: number;
  isLimitedAccess?: boolean;
  userInfoCommon: IUserInfo;
  avatarKey: string;
  setAvatarKey: Dispatch<SetStateAction<string>>;
}

export const EditUserInfoContext = createContext<IEditUserContext>({} as IEditUserContext);

export const Profile: FC<PropsWithChildren> = ({ children }) => {
  const { id = '' } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState<IUserInfo>(initForm);
  const currentId = id ? Number(id) : 0;
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const isLimitedAccess = !isAdmin(currentUser);

  const { classes } = useStyles();

  const [userNotFound, setUserNotFound] = useState(false);

  const { data, error } = useGetUserInfoQuery(currentId);

  const [avatarKey, setAvatarKey] = useState('');

  useEffect(() => {
    if (error && 'status' in error && error.status === 400) {
      setUserNotFound(true);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setUserData(data);
    }
  }, [data]);

  const initialValues: IEditUserContext = {
    currentId: currentId,
    isLimitedAccess: isLimitedAccess,
    userInfoCommon: userData,
    avatarKey: avatarKey,
    setAvatarKey: setAvatarKey
  };

  return (
    <ErrorBoundary componentTitle="EditUser page">
      <EditUserInfoContext.Provider value={initialValues}>
        {userNotFound ? (
          <NotFound />
        ) : (
          <Layout
            // rightNavbarContent={<div className={classes.fakeNavbar}></div>}
            leftNavbarContent={
              <SimpleNavbar
                widthNav={290}
                style={{ flexShrink: 0, borderRight: 'none' }}
                className={classes.simpleNavbar}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              >
                <NavMenu setIsOpen={setIsOpen} />
              </SimpleNavbar>
            }
          >
            <Box className={classes.container} ref={containerRef}>
              <Box className={classes.headerMenu}>
                <Burger
                  opened={isOpen}
                  onClick={() => setIsOpen(true)}
                  className={classes.headerMenuBurger}
                  size="sm"
                />
              </Box>
              <Outlet />
            </Box>
            <Box className={classes.section}>{children}</Box>
          </Layout>
        )}
      </EditUserInfoContext.Provider>
    </ErrorBoundary>
  );
};
