import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    marginBottom: '20px'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    marginTop: '-35px'
  },
  subtitle: {
    color: theme.colors.gray[6]
  },
  link: {
    padding: 0,
    fontWeight: 400,
    marginLeft: '5px',
    fontSize: '16px',
    height: 'auto',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },
  checkbox: {
    label: {
      color: theme.colors.gray[8],
      paddingLeft: '7px',
      lineHeight: '18px'
    }
  },
  checkboxLink: {
    fontSize: '14px',
    padding: 0,
    fontWeight: 400,
    height: 'auto',
    lineHeight: '18px',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent'
    }
  },
  controls: {
    '& .mantine-InputWrapper-error': {
      marginBottom: '10px',
      marginTop: '10px',
      fontSize: '14px',
      position: 'relative'
    },
    '& .mantine-Checkbox-error': {
      paddingLeft: '8px'
    },
    '& .mantine-Stepper-steps': {
      display: 'none'
    }
  },
  footerForm: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  },
  footerFormPrevButton: {
    border: `1px solid ${theme.colors.blue[6]}`,
    color: theme.colors.blue[6]
  },
  mailButton: {
    textAlign: 'center',
    marginTop: '30px',
    lineHeight: '28px',
    height: '100%',
    span: {
      whiteSpace: 'inherit'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  registerButton: {
    '& .mantine-Button-label': {
      overflow: 'visible'
    }
  },
  email: {
    '& .mantine-Input-input': {
      paddingRight: '130px'
    }
  },
  serviceEmailWrapper: {
    position: 'relative'
  },
  emailDomain: {
    position: 'absolute',
    top: '13px',
    right: '15px'
  },
  iconArrow: {
    position: 'absolute',
    left: '60px',
    [`@media (max-width: 575px)`]: {
      left: '40px'
    },
    [`@media (max-width: 435px)`]: {
      left: '20px'
    }
  }
}));
