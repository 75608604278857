import { Text } from '@mantine/core';
import { IColumnData } from '@components/simple-table/types';

interface ITableHeadProps {
  columns: IColumnData[];
}

export const TableHeadList = ({ columns }: ITableHeadProps) => {
  return (
    <tr>
      {columns.map(({ dataIndex, title, width }) => {
        return (
          <th key={dataIndex + title} style={{ width, minWidth: width }}>
            <Text weight={500} size="sm" color="gray.6" fw={400}>
              {title}
            </Text>
          </th>
        );
      })}
    </tr>
  );
};
