import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colors.backgroundGray[0],
    borderRight: 'none'
  },

  list: {
    padding: `0px 0px 0px ${theme.spacing.sm}`,
    [theme.fn.largerThan('md')]: {
      padding: `${theme.spacing.md} ${theme.spacing.sm} 0px ${theme.spacing.sm}`
    }
  }
}));
