import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text, Pagination, Divider, LoadingOverlay } from '@mantine/core';

import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';

import { useHandleAuthActions } from './hooks/useActions';
import { useGetAuthActionsFilters } from './hooks/useGetFilters';
import { useGetColumns } from './hooks/useGetColumns';
import { useDebounce } from '@hooks/useDebounce';

import {
  useGetAuthActionsHistoryQuery,
  useGetAuthActionsFilterQuery
} from '@/entities/admin-app/statistics/api';
import { transformConditionals } from '@/containers/pages/users/utils';
import { Search } from '@components/search/search-input';
import { FilterList } from '../../filter-list';
import { TableHeader } from '../../table-header';
import { PaginatedTable } from '@components/simple-table/PaginatedTable';
import { SearchIcon } from '@assets/icons';

import { convertToFilterConditions } from './utils';

import { IStatsFilters } from '../../../types';

import { useStyles as useCommonStyles } from '../../../styles';
import { useStyles } from './styles';
import { NoDataText } from '@/components/not-found/components/no-data-text';
import { useTranslation } from 'react-i18next';
import { initialStatisticFilter, listSizes } from '@/entities/admin-app/statistics/consts';

const pageSizeOptions = listSizes.map((page) => ({
  value: page.toString(),
  label: page.toString()
}));

const Audit = () => {
  const { t } = useTranslation();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const { selected: selectedUsers } = useAppSelector((state) => state.users);

  const {
    TotalCount,
    filters: actionsFilters,
    Actions: actionsHistory
  } = useAppSelector((state) => state.statistics.authActionsHistory);

  const [search, setSearch] = useState<string>(actionsFilters.Query || '');

  const [activeFilters, setActiveFilters] = useState<IStatsFilters>({} as IStatsFilters);

  const [rowData, setRowData] = useState<any[]>(actionsHistory);
  const { columns } = useGetColumns();
  const [pageSize, setPageSize] = useState<number>(actionsFilters.Count || listSizes[0]);
  const [activePage, setActivePage] = useState(1);
  const scrollRef = useRef<any>(null);

  const { setAuthActionsFilters, setAuthActionsHistory } = useActions();
  const { filtersMenuList } = useGetAuthActionsFilters();

  const { data, error, isLoading, isSuccess } = useGetAuthActionsHistoryQuery({
    FilterConditions: actionsFilters.FilterConditions,
    Query: actionsFilters.Query,
    Count: pageSize,
    Offset: actionsFilters.Offset
  });

  const handleResetFilters = () => {
    setPageSize(listSizes[0]);
    setActivePage(1);
    setAuthActionsFilters(initialStatisticFilter);
  };

  useEffect(() => {
    const initialActiveFilters = actionsFilters.FilterConditions
      ? transformConditionals(actionsFilters.FilterConditions)
      : {};

    setActiveFilters(initialActiveFilters as IStatsFilters);
  }, []);

  useEffect(() => {
    if (data) {
      setAuthActionsHistory(data);
    }
  }, [data]);

  useEffect(() => {
    setRowData(actionsHistory);
  }, [actionsHistory]);

  useEffect(() => {
    setAuthActionsFilters({
      ...actionsFilters,
      FilterConditions: convertToFilterConditions(activeFilters),
      Offset: 0
    });
    setActivePage(1);
  }, [activeFilters]);

  useEffect(() => {
    return () => {
      handleResetFilters();
    };
  }, []);

  const handleFilterChange = (updatedFilters: IStatsFilters) => {
    setActiveFilters(updatedFilters);
    scrollRef?.current?.el?.scrollTo(0, 0);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setActivePage(1);
    setAuthActionsFilters({
      ...actionsFilters,
      Offset: 0,
      Count: value
    });
  };

  const handleChangeActivePage = (value: number) => {
    setActivePage(value);
    setAuthActionsFilters({
      ...actionsFilters,
      Offset: (value >= 1 ? value - 1 : 0) * pageSize
    });
  };

  const debounced = useDebounce(search, 500);

  useHandleAuthActions({ scrollRef, search: debounced, authFilters: actionsFilters });

  return (
    <Box className={classes.wrapper} mr="md">
      <Flex direction="column" align="stretch" className={classes.container} mt="md" mb="sm">
        <Box className={classes.searchContainer}>
          <Search
            data-cy="auditSearch"
            icon={<SearchIcon />}
            setSearch={setSearch}
            className={classes.search}
            fullSize
            value={search}
            placeholder={t('statistics.search_by_name')}
          />
        </Box>
      </Flex>

      <Flex direction="column" align="stretch" className={classes.container}>
        <Flex direction="column" className={classes.contentContainer} px="md" py="md" mt="sm">
          <Text color="#101828" fw="600">
            {t('statistics.audit')}
          </Text>
          <Box className={classes.listWrapper}>
            <FilterList
              filtersMenuList={filtersMenuList}
              activeFilters={activeFilters}
              onFilterChange={handleFilterChange}
              onFilterReset={setActiveFilters}
              onLoadData={useGetAuthActionsFilterQuery}
            />
          </Box>
          <Divider className={classes.divider} mx="-md" mb="sm" />
          {rowData.length && TotalCount ? (
            <>
              <TableHeader
                handleChangePageSize={handleChangePageSize}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                isLoading={isLoading}
                startOffset={actionsFilters.Offset + 1}
                endOffset={Math.min(actionsFilters.Offset + pageSize, TotalCount)}
                totalCount={TotalCount}
              />
              <Box mt="lg">
                <PaginatedTable
                  allDataLength={TotalCount}
                  rowData={rowData}
                  columns={columns}
                  search={search}
                  height="100%"
                  width="100%"
                  selection={selectedUsers}
                  error={error}
                  scrollRef={scrollRef}
                  isLoading={isLoading}
                />
              </Box>
              <Box className={classes.paginateWrapper}>
                {TotalCount > pageSize && (
                  <Pagination
                    className={commonClasses.paginate}
                    value={activePage}
                    onChange={handleChangeActivePage}
                    total={Math.ceil(TotalCount / pageSize)}
                    withControls={false}
                    withEdges
                    size="sm"
                    position="center"
                  />
                )}
              </Box>
            </>
          ) : (
            isSuccess &&
            !isLoading &&
            !TotalCount && (
              <Flex align="center" justify="center" h="350px">
                <NoDataText />
              </Flex>
            )
          )}
          <LoadingOverlay visible={isLoading} />{' '}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Audit;
