import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { ActionIcon, Badge, Box, Button, TextInput, UnstyledButton } from '@mantine/core';
import { useStyles } from './styles';
import { IconPlus, IconX } from '@tabler/icons';
import { SelectGroupEnum } from '..';
import { IListItem } from '@/types';
import { directoriesApi, useCreateRoleMutation } from '@/entities/admin-app/directories/api';
import { clearApiCacheByTags } from '@/entities/helpers';
import { showNotification } from '@mantine/notifications';
import { themeColors } from '@/theme/colors';

interface IAddNewGroupProps {
  setActiveModal: Dispatch<SetStateAction<string>>;
  selectedGroup: IListItem;
  setSelectedGroup: (value: IListItem) => void;
  nameGroup: string;
  setNameGroup: Dispatch<SetStateAction<string>>;
}

export const AddNewGroup: FC<IAddNewGroupProps> = ({
  setActiveModal,
  selectedGroup,
  setSelectedGroup,
  nameGroup,
  setNameGroup
}) => {
  const { classes } = useStyles();

  const [fetchCreateRole] = useCreateRoleMutation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNameGroup(event.target.value);
  };

  const goBack = () => {
    setActiveModal(SelectGroupEnum.selectGroup);
  };

  const goNext = () => {
    setActiveModal(SelectGroupEnum.setParentGroup);
  };

  const createRole = async () => {
    try {
      const res = await fetchCreateRole({
        Id: 0,
        Name: nameGroup,
        IsRemovable: false,
        ParentId: Number(selectedGroup.value)
      });
      if ('data' in res) {
        clearApiCacheByTags(directoriesApi, ['RolesWithCounter']);

        showNotification({
          title: `Группа ${nameGroup}, успешно создана!`,
          message: '',
          autoClose: true,
          color: 'green'
        });
        goBack();
      }
    } catch (e) {
      console.log('fetchCreateRole ERROR', e);
    }
  };

  const removeSelectedGroup = () => {
    setSelectedGroup({} as IListItem);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <TextInput
          className={classes.textFieldInput}
          label="Придумайте название"
          value={nameGroup}
          onChange={handleChange}
        />
        {'value' in selectedGroup ? (
          <Badge
            className={classes.badge}
            variant="outline"
            rightSection={
              <ActionIcon size={16} variant="transparent" onClick={removeSelectedGroup}>
                <IconX size={16} color={themeColors.brandGray[0]} />
              </ActionIcon>
            }
          >
            {selectedGroup.label}
          </Badge>
        ) : (
          <Button
            className={classes.addBtn}
            size="sm"
            variant="outline"
            rightIcon={<IconPlus />}
            onClick={goNext}
            disabled={!nameGroup}
          >
            Добавить родительскую группу
          </Button>
        )}
      </Box>

      <Box className={classes.footer}>
        <Button size="sm" onClick={createRole} radius={8} w="49%" disabled={Boolean(!nameGroup)}>
          Создать
        </Button>

        <UnstyledButton ml={8} className={classes.cancelBtn} w="49%" onClick={goBack}>
          Назад
        </UnstyledButton>
      </Box>
    </Box>
  );
};
