import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  navLinkItem: {
    '&.mantine-NavLink-root': {
      borderRadius: '8px',
      // padding: '8px 12px',
      '&[data-active=true]': {
        backgroundColor: theme.colors.brandGray[1],
        fontWeight: 'bold',
        color: '#101828'
      },
      [theme.fn.smallerThan('md')]: {
        paddingRight: '8px',
        paddingLeft: '8px'
      }
    }
  }
}));
