import React, { FC, useCallback, useEffect } from 'react';
import { Box, Flex, Text, SimpleGrid, LoadingOverlay } from '@mantine/core';
import { useAppSelector } from '@hooks/redux/redux';
import { useStyles } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { useLazyGetInvitationLinksListQuery } from '@/entities/admin-app/auth/api';
import { InviteLinkItem } from '@/containers/pages/filials/edit/components/tabs/invite-links/inviteLinkItem';
import { CreateInviteLink } from '@/containers/pages/filials/edit/components/tabs/invite-links/createInviteLink';

const InviteLinksList: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);
  const [getInvitationLinks, { data, isLoading }] = useLazyGetInvitationLinksListQuery();

  const handleChange = useCallback(() => {
    filial?.Id && getInvitationLinks(filial.Id);
  }, [filial]);

  useEffect(() => {
    if (!filial) {
      return;
    }
    if (filial.Id) {
      getInvitationLinks(filial.Id);
    }
  }, [filial]);

  return (
    <Box className={classes.container}>
      <Flex className={classes.section} align="flex-start" direction="column">
        <Text className={classes.title}>{t('filials.inviteLinks.title')}</Text>
        {filial && data?.Invitations.length
          ? data?.Invitations.map((invitationLink, index: number) => {
              return (
                <InviteLinkItem
                  CustomerId={filial?.Id}
                  invitationLink={invitationLink}
                  handleChange={handleChange}
                  key={index}
                />
              );
            })
          : null}
        {filial ? (
          <SimpleGrid cols={1} mt="sm">
            <CreateInviteLink handleChange={handleChange} CustomerId={filial?.Id} />
          </SimpleGrid>
        ) : null}
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
      </Flex>
    </Box>
  );
};

export default InviteLinksList;
