import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.fn.largerThan('xs')]: {
      flexDirection: 'row'
    },
    [theme.fn.smallerThan('xs')]: {
      button: {
        width: '170px',
        marginBottom: 20
      }
    }
  },
  rightSection: {
    input: {
      width: '85px'
    }
  },

  countText: {
    [theme.fn.smallerThan('xs')]: {
      fontSize: theme.fontSizes.xs,
      marginRight: 10
    }
  },

  selectItem: {
    backgroundColor: theme.white,
    cursor: 'pointer',
    padding: '6px',
    '.mantine-Radio-radio': {
      width: theme.spacing.sm,
      height: theme.spacing.sm
    }
  }
}));
