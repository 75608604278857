import { Box } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { useDeleteAvatarMutation } from '@/entities/admin-app/user/api';
import { themeColors } from '@/theme/colors';
import { useActions } from '@/hooks/redux/action';
import { useAppSelector } from '@/hooks/redux/redux';
import { IUser } from '@/entities/admin-app/auth';
import { useParams } from 'react-router-dom';
import { useLazyGetUserInfoQuery } from '@/entities/admin-app/user/api';
import { useContext } from 'react';
import { EditUserInfoContext } from '..';
import { useTranslation } from 'react-i18next';

export const useDeleteAvatarModal = (id: number) => {
  const { t } = useTranslation();
  const [deleteAvatar, { isSuccess, data }] = useDeleteAvatarMutation();
  const [fetchUserInfo] = useLazyGetUserInfoQuery();
  const { setUserInfo } = useActions();
  const { User } = useAppSelector((state) => state.userInfo);
  const { setAvatarKey } = useContext(EditUserInfoContext);
  const params = useParams();

  const handleDeleteAvatar = async (id: number) => {
    try {
      await deleteAvatar(Number(id));
      if (params.id) {
        if (params.id === User?.Id.toString()) {
          setUserInfo({ ...User, HasAvatar: false, AvatarKey: '' } as IUser);
        }
        setAvatarKey('');
        fetchUserInfo(Number(params.id));
      }
    } catch (e) {
      console.log('useDeleteAvatarModal ERROR', e);
    }
  };

  const openDeleteAvatar = () => {
    openContextModal({
      modal: 'context',
      centered: true,
      innerProps: {
        title: t('warning'),
        modalBody: <Box mb={24}>{t('avatar.confirmDelete')}</Box>,
        confirmProps: {
          color: 'brandRed.1',
          sx: { backgroundColor: themeColors.brandRed[1], color: 'white', width: '49%' }
        },
        cancelProps: {
          sx: {
            backgroundColor: themeColors.brandGray[1],
            color: 'black',
            width: '49%'
          }
        },
        labels: { confirm: t('delete'), cancel: t('cancel') },
        onConfirm: () => handleDeleteAvatar(id)
      }
    });
  };

  return { openDeleteAvatar, data, isSuccess };
};
