import { FC, useCallback } from 'react';
import { IconTrash, IconEye } from '@tabler/icons';
import { IRowData } from '@components/simple-table/types';
import {
  ActionIcon,
  // Modal,
  Box,
  Input,
  Text,
  Button,
  // SimpleGrid,
  UnstyledButton,
  Code,
  Group
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { useDisclosure } from '@mantine/hooks';
import { useDeleteFilial } from '@/containers/pages/filials/hooks/useDeleteFilial';
import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';
import { useStyles } from '../../../styles';
import { Modal } from '@/components/modal';
import { useTranslation } from 'react-i18next';
import { EditProfileIcon } from '@/assets/icons';
import { useNavigate } from 'react-router-dom';

interface IActionProps {
  item?: IRowData;
}

export const Action: FC<IActionProps> = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [opened, { close, open }] = useDisclosure(false);
  const [value, setValue] = useInputState('');
  const { setSelectedFilialsId } = useActions();
  const { selectedFilialsId } = useAppSelector((state) => state.filials);
  const { Settings } = useAppSelector((state) => state.userInfo);
  const { deleteFilial } = useDeleteFilial();
  const { classes } = useStyles();

  const onClickDelete = useCallback(
    async (event: React.SyntheticEvent<HTMLButtonElement>) => {
      setSelectedFilialsId(0);
      event.stopPropagation();
      await deleteFilial(item?.Id);
      setValue('');
      close();
    },
    [item]
  );

  const handleCloseModal = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.nativeEvent.stopPropagation();
    setValue('');
    close();
  };

  const handleClickRemove = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    open();
  };

  const validate = () => {
    return value === item?.Title;
  };

  const goEditFilial = (id: number) => {
    navigate(`/filials/${id}`);
  };

  return (
    <>
      <Group spacing={4} position="right" noWrap>
        {!selectedFilialsId && (
          <>
            <ActionIcon
              className="action-icon"
              data-action="action"
              data-cy="filialSettingsBtn"
              onClick={() => goEditFilial(item?.Id)}
            >
              <EditProfileIcon size={24} />
            </ActionIcon>
            <ActionIcon className="action-icon" data-action="action" data-cy="viewDetailsBtn">
              <IconEye
                size="1.5rem"
                color="#667085"
                stroke={1.5}
                style={{ pointerEvents: 'none' }}
              />
            </ActionIcon>
          </>
        )}

        {!isSaaS(Settings) ? (
          <ActionIcon
            className="action-icon"
            onClick={handleClickRemove}
            data-action="action"
            data-cy="deleteFilialBtn"
          >
            <IconTrash
              size="1.5rem"
              color="#667085"
              stroke={1.5}
              style={{ pointerEvents: 'none' }}
            />
          </ActionIcon>
        ) : null}
      </Group>

      <Modal opened={opened} onClose={close} title={t('warning')} zIndex={1002}>
        <Box className={classes.modalRoot}>
          {item?.Parent === null || item?.ParentName === null ? (
            <Box className={classes.modalContainer}>
              <Text>{t('filials.deleteFilial.rootFilial')}</Text>
            </Box>
          ) : item?.UsersCount ? (
            <Box className={classes.modalContainer}>
              <Text>{t('filials.deleteFilial.usersFilial')}</Text>
            </Box>
          ) : (
            <>
              <Box className={classes.modalContainer}>
                <Box mb="1rem">
                  <Text mb="1rem" fz="md">
                    {t('filials.deleteFilial.confirm')}{' '}
                    <b className={classes.preserveSpaces}>{item?.Title}</b>?
                  </Text>
                  <Text fz="md">
                    {t('filials.deleteFilial.insert')}{' '}
                    <Code color="red" className={classes.preserveSpaces}>
                      {item?.Title}
                    </Code>
                  </Text>
                </Box>
                <Input
                  placeholder={t('login.customerCode')}
                  w="100%"
                  required
                  value={value}
                  onChange={setValue}
                />
              </Box>

              <Box className={classes.modalFooter}>
                <Button color="red" w="49%" onClick={onClickDelete} disabled={!validate()}>
                  {t('delete')}
                </Button>
                <UnstyledButton
                  className={classes.modalCancelBtn}
                  w="49%"
                  onClick={handleCloseModal}
                >
                  {t('cancel')}
                </UnstyledButton>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};
